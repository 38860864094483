import React from 'react'
import {IconProps} from '../../store/types'

const ClassicIcon = (props: IconProps) => {
    return <svg className={props.className} viewBox="0 0 20 20" width="20" height="20" fill="none"
                xmlns="http://www.w3.org/2000/svg">
        <path fillRule="evenodd" clipRule="evenodd"
              d="M17.2 16.67 10 3.48 2.8 16.67h14.4ZM11.45 2.68a1.67 1.67 0 0 0-2.92 0l-7.2 13.19c-.6 1.1.2 2.46 1.47 2.46h14.38a1.67 1.67 0 0 0 1.47-2.46l-7.2-13.19Z"></path>
    </svg>
}

export default ClassicIcon
