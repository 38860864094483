import React from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {useTranslation} from 'react-i18next'
import {getUser, signUserNonce} from '../../store/authSlice'
import {AppDispatch} from '../../store/store'
import {SignPencilIcon} from '../icons'

const HeaderAuthAlert = () => {
    const {t} = useTranslation()
    const user = useSelector(getUser)
    const dispatch = useDispatch<AppDispatch>()

    const signMessage = () => {
        dispatch(signUserNonce())
    }

    if (!user || user.auth) {
        return null
    }

    return <div className="alert alert-warning mb-0 rounded-0 has-pointer">
        <div className="container">
            <div className="d-flex" onClick={signMessage}>
                <SignPencilIcon className={'flex-shrink-0 me-3'}/>
                <span className="mt-1">{t('alert.headerSignAuthMessage')}</span>
            </div>
        </div>
    </div>

}

export default HeaderAuthAlert
