import React, {ReactNode} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {ethers} from 'ethers'
import {useTranslation} from 'react-i18next'
import {checkENSName} from '../../utils/functions'
import {getCurrentNetwork} from '../../store/appSlice'
import {getName, requestName} from '../../store/ensSlice'
import {CHAINS} from '../../utils/constants'
import {AppDispatch} from '../../store/store'
import {LabelElement} from './index'

interface propsType {
    additionalClass?: string
    alert?: string
    disabled?: boolean
    error?: boolean
    errorText?: string
    infoText?: string
    isAddress?: boolean
    isValid?: boolean
    label?: ReactNode
    minWidth?: number
    onChange: (value: string) => void
    placeholder?: string
    readonly?: boolean
    setError?: (text: string) => void
    small?: boolean
    value: string
    validText?: string
}

const InputElement = (props: propsType) => {
    const {t} = useTranslation()
    const currentNetwork = useSelector(getCurrentNetwork)
    const nameAddress = useSelector(getName(props.value))

    const dispatch = useDispatch<AppDispatch>()

    let style: any = {}
    if (props.minWidth) {
        style.minWidth = props.minWidth
    }

    const checkAddress = (value: string): void => {
        if (!props.isAddress) {
            props.onChange(value)
            return
        }

        if (!/^[a-zA-Z0-9.]*$/.test(value)) {
            props.setError?.(`${t('error.wrong', {name: t('form.label.address')})} (a-Z, 0-9, ".")`)
            return
        }

        props.onChange(value)
        if (value === '' || ethers.utils.isAddress(value)) {
            props.setError?.('')
            return
        }

        if (!currentNetwork || CHAINS[currentNetwork].tld === '') {
            props.setError?.(t('error.wrongAddressOrNetwork'))
            return
        }

        if (checkENSName(value, currentNetwork)) {
            props.setError?.('')
            dispatch(requestName(value))
        } else {
            props.setError?.(t('error.wrong', {name: t('form.label.address')}))
        }
    }

    const error = props.errorText && props.errorText !== '' ? true : props.error
    let alertText = ''
    if (props.isAddress && checkENSName(props.value, currentNetwork)) {
        alertText = nameAddress !== undefined ?
            nameAddress === '' ?
                t('error.nameNotFound')
                :
                nameAddress
            :
            `${t('status.loadingName')}...`
    }
    if (props.alert && props.alert !== '') {
        alertText = props.alert
    }
    let message: ReactNode = undefined
    if (props.errorText && props.errorText !== '') {
        message = <div className="invalid-feedback">{props.errorText}</div>
    } else if (alertText !== '') {
        message = <div className="warning-feedback">{alertText}</div>
    } else if (props.validText && props.validText !== '') {
        message = <div className="valid-feedback">{props.validText}</div>
    } else if (props.infoText && props.infoText !== '') {
        message = <div className="info-feedback">{props.infoText}</div>
    }

    return <>
        {props.label ? <LabelElement>{props.label}</LabelElement> : null}
        <input
            className={
                `form-control ${props.small ? 'form-control-sm' : ''} ${error ?
                    'is-invalid'
                    :
                    props.validText && props.validText !== '' ?
                        'is-valid'
                        :
                        ''
                } ${props.additionalClass || ''}`
            }
            type="text"
            style={style}
            value={props.value}
            onChange={(e) => {
                e.preventDefault()
                checkAddress(e.target.value)
            }}
            placeholder={props.placeholder}
            readOnly={props.readonly}
            disabled={props.disabled}
        />
        {message}
    </>
}

export default InputElement
