import React, {FC, PropsWithChildren, useState} from 'react'
import {ThemeContext} from '.'
import {TDesignMode, TShowcaseType} from '../store/types'
import {ENVIRONMENT} from '../utils/constants'

const ThemeProvider: FC<PropsWithChildren> = ({children}) => {
    let value = localStorage.getItem('darkTheme')
    if (ENVIRONMENT === 'prod') {
        value = 'true'
    }
    const [dark, setDark] = useState(value ? value === 'true' : true)
    const [designMode, setDesignMode] = useState<TDesignMode | null>(null)
    const [showcaseType, setShowcaseType] = useState<TShowcaseType | null>(null)

    const toggleDark = () => {
        setDark(!dark)
        localStorage.setItem('darkTheme', !dark ? 'true' : 'false')
    }

    return <ThemeContext.Provider
            value={{
                dark,
                toggleDark,
                designMode,
                changeDesignMode: setDesignMode,
                showcaseType,
                changeShowcaseType: setShowcaseType,
            }}
        >
            {children}
        </ThemeContext.Provider>
}

export default ThemeProvider
