import React, {ReactNode, useEffect, useMemo, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {useTranslation} from 'react-i18next'
import i18next from 'i18next'
import {AlertElement, ButtonElement, InputElement} from '../elements'
import {getCurrentNetwork, setModalError} from '../../store/appSlice'
import {checkENSName} from '../../utils/functions'
import {AppDispatch} from '../../store/store'
import {getNames} from '../../store/ensSlice'
import {RecipientRow} from './index'
import {addRecipient, delRecipient, getRecipients, setRecipients, setRecipientsError} from '../../store/inputSlice'

const RecipientsBlock = () => {
    const {t} = useTranslation()
    const [newRecipient, setNewRecipient] = useState<string>('')
    const [newRecipientError, setNewRecipientError] = useState<string>('')
    const currentNetwork = useSelector(getCurrentNetwork)
    const ENSNames = useSelector(getNames)
    const recipients = useSelector(getRecipients)

    const dispatch = useDispatch<AppDispatch>()

    useEffect(() => {
        return () => {
            dispatch(setRecipients([]))
        }
    }, [])

    const addRecipientHandler = () => {
        if (newRecipientError !== '' || newRecipient === '') {
            return
        }

        if (recipients.value.indexOf(newRecipient) >= 0 || recipients.value.indexOf(ENSNames[newRecipient]) >= 0) {
            dispatch(setModalError({text: t('error.recipientAlreadyExist')}))
            return
        }

        dispatch(addRecipient(checkENSName(newRecipient, currentNetwork) ? ENSNames[newRecipient] : newRecipient))
        dispatch(setRecipientsError(null))
        setNewRecipient('')
    }
    const delRecipientHandler = (index: number) => {
        if (recipients.value.length > index && index >= 0) {
            dispatch(delRecipient(index))
            if (recipients.value.length === 1) {
                dispatch(setRecipientsError(t('error.mustBeGreater', {name: t('form.label.recipients'), value: 0})))
            }
        }
    }

    let recipientsErrorText = ''
    if (newRecipientError) {
        recipientsErrorText = newRecipientError
    } else if (recipients.error.status) {
        recipientsErrorText = recipients.error.text
    }
    const recipientsList = useMemo(() => {
        if (recipients.value.length === 0) {
            return <AlertElement>{t('status.emptyList')}</AlertElement>
        }

        let list: ReactNode[] = []
        for (let i = recipients.value.length - 1; i >= 0; i--) {
            const addr = recipients.value[i]
            list.push(<RecipientRow key={i} address={addr} delHandler={delRecipientHandler} index={i}/>)
        }
        return list
    }, [recipients.value, ENSNames, i18next.language])

    return <div className="mb-6 pb-4">
        <div className="upload-container for-tables">
            <div className="upload-poopover">
                <div className="inner">
                    <div className="h3 mb-0">{t('action.dropFile')}</div>
                </div>
            </div>
            <h2 className="mb-4">{t('form.label.recipients')}</h2>
            <div className="row gx-2 mb-lg-2 pe-lg-2">
                <div className="col-lg-8 col-xl-7">
                    <div className="mb-3 mb-lg-0">
                        <InputElement
                            onChange={setNewRecipient}
                            value={newRecipient}
                            isAddress
                            errorText={recipientsErrorText}
                            setError={setNewRecipientError}
                            placeholder={t('form.label.recipientAddress')}
                        />
                    </div>
                </div>
                <div className="col-lg-2 col-xl-2">
                    <div className="mb-3 mb-lg-0">
                        <ButtonElement
                            outline
                            additionalClass={'w-100'}
                            onClick={addRecipientHandler}
                        >{t('button.add')}</ButtonElement>
                    </div>
                </div>
            </div>
            <div className="text-center text-lg-start">
                <button className="btn btn-link px-0 py-1">{t('button.uploadCsv')}</button>
                <span className="d-none d-lg-inline"> {t('action.orDragAndDrop')} </span>
                {t('word.or')}
                {' '}
                <button className="btn btn-link px-0 py-1">{t('button.takeFromWhitelist')}</button>
            </div>
            <div className="mt-3">
                {/*
                            <div className="mb-2">2 out of <span className="text-muted">25</span></div>
*/}
                <div className="row">
                    <div className="col-lg-10 col-xl-9">
                        <div className="row-table row-table_with-scroll">
                            {recipientsList}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
}

export default RecipientsBlock
