import React, {useRef, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {useTranslation} from 'react-i18next'
import {getModalEditString, setModalEditString} from '../../store/appSlice'
import {ButtonElement, InputElement} from '../elements'
import {AppDispatch} from '../../store/store'
import {CloseIcon} from '../icons'

interface propsType {
    show: boolean
}

const ModalEditString = (props: propsType) => {
    const {t} = useTranslation()
    const modalFade = useRef(null)
    const modal = useSelector(getModalEditString)
    const [text, setText] = useState(modal?.text || '')
    const [textError, setTextError] = useState('')

    const dispatch = useDispatch<AppDispatch>()

    const closeModal = () => {
        dispatch(setModalEditString(null))
    }
    const editHandler = () => {
        if (!modal) {
            return
        }

        let error = false
        if (text === '') {
            error = true
            setTextError(t('error.wrong', {name: modal.label}))
        } else {
            setTextError('')
        }

        if (error) {
            return
        }
        modal.handler(text)
    }

    if (!modal) {
        closeModal()
        return null
    }

    if (!props.show) {
        return null
    }

    return <div
        className={`modal modal-lg fade show`}
        ref={modalFade}
        tabIndex={-1}
        aria-labelledby="Edit"
        aria-hidden="true"
        style={{display: 'block'}}
        onMouseDown={(e) => {
            if (e.target === modalFade.current) {
                closeModal()
            }
        }}
    >
        <div className="modal-dialog">
            <div className="modal-content">
                <div className="modal-header">
                    <h2 className="modal-title">{modal.title || t('button.edit')}</h2>
                    <button className="btn p-2 btn-with-opacity btn-modal-close" onClick={closeModal}>
                        <CloseIcon/>
                    </button>
                </div>
                <div className="modal-body pt-0">
                    <div className="row">
                        <div className="col-12">
                            <div className="mb-4">
                                <InputElement
                                    value={text}
                                    onChange={setText}
                                    errorText={textError}
                                    label={modal.label}
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="modal-footer justify-content-end">
                    <ButtonElement
                        additionalClass={'flex-grow-1 flex-lg-grow-0'}
                        onClick={editHandler}
                    >{t('button.save')}</ButtonElement>
                </div>
            </div>
        </div>
    </div>
}

export default ModalEditString
