import {configureStore} from '@reduxjs/toolkit'
import appReducer from './appSlice'
import authReducer from './authSlice'
import coinsReducer from './coinsSlice'
import ensReducer from './ensSlice'
import eventsReducer from './eventsSlice'
import giftsReducer from './giftsSlice'
import inputReducer from './inputSlice'
import launchpadReducer from './launchpadSlice'
import lazyMintingReducer from './lazyMintingSlice'
import mintReducer from './mintSlice'
import onChainEventsReducer from './onChainEventsSlice'
import organizersReducer from './organizersSlice'
import profileReducer from './profileSlice'
import sbtReducer from './sbtSlice'
import showcaseV2Reducer from './showcaseV2Slice'
import statisticReducer from './statisticSlice'
import ticketsReducer from './ticketsSlice'
import tokensReducer from './tokensSlice'
import userReducer from './userSlice'
import variablesReducer from './variablesSlice'

const store = configureStore({
    reducer: {
        app: appReducer,
        auth: authReducer,
        coins: coinsReducer,
        ens: ensReducer,
        events: eventsReducer,
        gifts: giftsReducer,
        input: inputReducer,
        launchpad: launchpadReducer,
        lazyMinting: lazyMintingReducer,
        mint: mintReducer,
        onChainEvents: onChainEventsReducer,
        organizers: organizersReducer,
        profile: profileReducer,
        sbt: sbtReducer,
        showcaseV2: showcaseV2Reducer,
        statistic: statisticReducer,
        tickets: ticketsReducer,
        tokens: tokensReducer,
        user: userReducer,
        variables: variablesReducer,
    },
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            serializableCheck: {
                ignoredActions: [
                    'app/addChainQueue',
                    'app/addRequestToQueue',
                    'app/addToRequestQueue',
                    'app/setLoadedImage',
                    'app/setModalConfirmation',
                    'app/setModalEditString',
                    'app/setModalEditTicketPrices',
                    'app/setModalSelect',
                    'app/setModalSendTransactions',
                    'app/setSigner',
                    'events/setEvents',
                    'events/setEventToObject',
                    'events/setThirdpartyEvents',
                    'launchpad/requestShowcase/fulfilled',
                    'launchpad/selectedTicketHandler',
                    'lazyMinting/requestShowcase/fulfilled',
                    'lazyMinting/selectedTicketHandler',
                    'mint/setMintContract',
                    'showcaseV2/requestShowcase',
                    'showcaseV2/requestShowcases',
                    'showcaseV2/selectedTicketHandler',
                    'tickets/setTickets',
                    'tickets/setUserTicketsByEvent',
                    'tokens/requestToken',
                ],
                ignoredPaths: [
                    'app.chainQueue',
                    'app.loadedImage',
                    'app.modalConfirmation.confirmAction',
                    'app.modalEditString.afterEditHandler',
                    'app.modalEditString.handler',
                    'app.modalEditTicketPrices',
                    'app.modalSelect.addBlock',
                    'app.modalSelect.filters',
                    'app.modalSelect.reloadList',
                    'app.modalSelect.setId',
                    'app.modalSendTransactions',
                    'app.requestQueue',
                    'app.signer',
                    'app.web3',
                    'events.events',
                    'events.eventsObject',
                    'events.thirdpartyEvents',
                    'launchpad.selectedTickets',
                    'launchpad.showcase.items',
                    'lazyMinting.selectedTickets',
                    'lazyMinting.showcase.items',
                    'mint.mintContract',
                    'showcaseV2.showcase',
                    'showcaseV2.showcases',
                    'showcaseV2.selectedTickets',
                    'tickets.tickets',
                    'tickets.userTicketsByEvent',
                    'tickets.whitelist',
                    'tokens.tokens',
                ],
            },
        }),
})

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch

export default store
