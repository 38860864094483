import React from 'react'
import {IconProps} from '../../store/types'

const OnChainIcon = (props: IconProps) => {
    return <svg className={props.className} viewBox="0 0 20 20" width="20" height="20" fill="none"
                xmlns="http://www.w3.org/2000/svg">
        <path fillRule="evenodd" clipRule="evenodd"
              d="M16.67 3.33H3.33v13.34h13.34V3.33ZM3.33 1.67c-.92 0-1.66.74-1.66 1.66v13.34c0 .92.74 1.66 1.66 1.66h13.34c.92 0 1.66-.74 1.66-1.66V3.33c0-.92-.74-1.66-1.66-1.66H3.33Z"></path>
    </svg>
}

export default OnChainIcon
