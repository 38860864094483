import React, {useEffect, useMemo, useState} from 'react'
import {useParams} from 'react-router-dom'
import {useDispatch, useSelector} from 'react-redux'
import {AddToCalendarButton} from 'add-to-calendar-button-react'
import {Trans, useTranslation} from 'react-i18next'
import {getCurrentEventId, getEvent, requestCurrentEvent, setCurrentEventId} from '../../store/eventsSlice'
import {AppDispatch} from '../../store/store'
import {
    getCurrentNetwork,
    getWalletAddress, sendRequestWithAuth,
    setModalAddShowcaseToEvent,
    setModalEditEvent,
    setModalEditString
} from '../../store/appSlice'
import {getUserTicketsByEvent, requestUserTicketsByEvent, setUserTicketsByEvent} from '../../store/ticketsSlice'
import {EventTicketItem} from '../tickets'
import {getUser} from '../../store/authSlice'
import {APP_URL, TG_AUTH_BOT, VAR_KEYS} from '../../utils/constants'
import {AddIcon, CalendarIcon, EditIcon, LoadingIcon} from '../icons'
import {AlertElement, ButtonElement, CopyToClipboardBlock, TextareaElement} from '../elements'
import {getVariable, requestVariable, sendVariable, setVariable} from '../../store/variablesSlice'
import {ShowcaseRow} from './index'
import {getDateRangeString, getDateTime} from '../../utils/functions'
import {putOrganizerTitle} from '../../store/organizersSlice'

const Event = () => {
    const {t} = useTranslation()
    const {organizerUrl, eventUrl} = useParams()
    const [editingTicketDescription, setEditingTicketDescription] = useState(false)
    const [loadingTickets, setLoadingTickets] = useState(false)
    const [selectedTab, setSelectedTab] = useState(0)
    const [ticketDescription, setTicketDescription] = useState('')
    const currentNetwork = useSelector(getCurrentNetwork)
    const currentEventId = useSelector(getCurrentEventId)
    const event = useSelector(getEvent(currentEventId))
    const eventTicketDescription = useSelector(getVariable(`${VAR_KEYS.EventTicketDescription}-${currentEventId}`))
    const userTickets = useSelector(getUserTicketsByEvent)
    const user = useSelector(getUser)
    const walletAddress = useSelector(getWalletAddress)
    let startDate = ''
    let startTime = ''
    let endDate = ''
    let endTime = ''
    let eventDate = ''
    if (event) {
        eventDate = getDateRangeString(event.startTime, event.endTime)
        let datetime = getDateTime(event.startTime, true)
        startDate = datetime.date
        startTime = datetime.time
        datetime = getDateTime(event.endTime, true)
        endDate = datetime.date
        endTime = datetime.time
    }

    const dispatch = useDispatch<AppDispatch>()

    useEffect(() => {
        const timer = setInterval(() => {
            setLoadingTickets(true)
        }, 15000)
        return () => {
            clearInterval(timer)
            dispatch(setUserTicketsByEvent(null))
        }
    }, [])
    useEffect(() => {
        return () => {
            if (currentEventId) {
                dispatch(setVariable({key: `${VAR_KEYS.EventTicketDescription}-${currentEventId}`, value: null}))
            }
            dispatch(setCurrentEventId(null))
        }
    }, [organizerUrl, eventUrl])
    useEffect(() => {
        if (!organizerUrl || !eventUrl) {
            dispatch(setCurrentEventId(null))
        } else if (!event) {
            dispatch(requestCurrentEvent({organizerUrl, eventUrl}))
        }
    }, [event, user])
    useEffect(() => {
        if (currentEventId) {
            dispatch(requestVariable({key: VAR_KEYS.EventTicketDescription, foreignKey: currentEventId.toString()}))
        }
    }, [currentEventId])
    useEffect(() => {
        if (!loadingTickets) {
            setLoadingTickets(true)
        }
    }, [currentNetwork, walletAddress, currentEventId])
    useEffect(() => {
        if (loadingTickets) {
            if (currentNetwork && walletAddress && currentEventId) {
                dispatch(requestUserTicketsByEvent())
            } else {
                setLoadingTickets(false)
            }
        }
    }, [loadingTickets])
    useEffect(() => {
        if (userTickets) {
            setLoadingTickets(false)
        }
    }, [userTickets])

    const showcasesList = useMemo(() => {
        if (!event || !event.showcases) {
            return null
        }

        return event.showcases.map((item) => (<ShowcaseRow key={item.id} showcase={item}/>))
    }, [event])
    const ticketsList = useMemo(() => {
        if (!userTickets) {
            return <div>{t('status.loading')}...</div>
        }

        if (userTickets.length === 0) {
            return <div>{t('status.emptyList')}</div>
        }

        return userTickets.map((item, index) => (<EventTicketItem key={index} item={item}/>))
    }, [userTickets])

    const addShowcaseHandler = () => {
        dispatch(setModalAddShowcaseToEvent(true))
    }
    const editEventHandler = () => {
        dispatch(setModalEditEvent(true))
    }
    const editOrganizerHandler = () => {
        if (!event || !organizerUrl || !eventUrl) {
            return
        }

        dispatch(setModalEditString({
            title: t('modal.title.edit', {name: t('form.label.organizerName')}),
            label: t('form.label.organizerName'),
            text: event.organizerTitle,
            handler: (text: string) => {
                dispatch(sendRequestWithAuth(putOrganizerTitle({organizerId: event.organizerId, title: text})))
            },
        }))
    }
    const editTicketDescriptionHandler = () => {
        setEditingTicketDescription(true)
        setTicketDescription(eventTicketDescription || '')
    }
    const saveTicketDescriptionHandler = () => {
        if (!currentEventId) {
            return
        }

        dispatch(sendVariable({
            key: VAR_KEYS.EventTicketDescription,
            foreignKey: currentEventId.toString(),
            value: ticketDescription,
        }))
        setEditingTicketDescription(false)
    }

    return <div className="page-content">
        <div className="container">
            <nav>
                <ol className="breadcrumb">
                    {/*
                    <li className="breadcrumb-item"><Link to={'/calendar'}>{t('section.calendar')}</Link></li>
*/}
                    <li className="breadcrumb-item active">{t('section.calendar')}</li>
                    <li className="breadcrumb-item active" aria-current="page">{t('section.event')}</li>
                </ol>
            </nav>
        </div>
        <div className="container">
            {event === null ?
                <div className="h4">{t('status.loading')}...</div>
                :
                event === undefined ?
                    <div className="h4">{t('error.eventNotFound')}</div>
                    :
                    <div className="row">
                        <div className="col-lg-10 offset-lg-1 col-xl-8 offset-xl-2">
                            <div className="mb-4">
                                <div className="row">
                                    <div className="col">
                                        <h1 className="mb-1">{event.title}</h1>
                                        <span className="text-primary">
                                            {t('event.card.by')} {event.organizerTitle}
                                            {event.organizer === walletAddress ?
                                                <>
                                                    {' '}
                                                    <ButtonElement
                                                        small
                                                        outline
                                                        type={'secondary'}
                                                        additionalClass={'border-0 p-1'}
                                                        onClick={editOrganizerHandler}
                                                    >
                                                        <EditIcon/>
                                                    </ButtonElement>
                                                </>
                                                :
                                                null
                                            }
                                        </span>
                                    </div>
                                    {event.organizer === walletAddress ?
                                        <div className="col-auto">
                                            <ButtonElement
                                                link
                                                additionalClass={'p-2 mt-n2 d-md-none'}
                                                onClick={editEventHandler}
                                            >
                                                <span><EditIcon/></span>
                                            </ButtonElement>
                                            <ButtonElement
                                                link
                                                additionalClass={'mt-n1 d-none d-md-flex px-0'}
                                                onClick={editEventHandler}
                                            >
                                                <span className="me-2"><EditIcon/></span>
                                                <span>{t('button.edit')}</span>
                                            </ButtonElement>
                                        </div>
                                        :
                                        null
                                    }
                                </div>
                            </div>
                            <div className="mb-5 pb-2">
                                <div className="row gx-6 align-items-center">
                                    <div className="col-12 col-md mb-3 mb-md-2">
                                        <div className="d-flex">
                                            <CalendarIcon className="icon-primary me-2"/>
                                            <span className="mt-1">
                                                {eventDate}
                                            </span>
                                        </div>
                                    </div>
                                    {/*
                                    <div className="col-12 col-md mb-3 mb-md-2">
                                        <div className="d-flex">
                                            <svg className="icon-primary me-2" width="24" height="24"
                                                 fill="none"
                                                 xmlns="http://www.w3.org/2000/svg">
                                                <path fillRule="evenodd" clipRule="evenodd"
                                                      d="M12 3.4a6.51 6.51 0 0 0-6.51 6.51c0 1.02.39 2.21 1.05 3.47.67 1.26 1.56 2.5 2.48 3.62A38.22 38.22 0 0 0 12 20.23 38.04 38.04 0 0 0 14.98 17a22.63 22.63 0 0 0 2.48-3.62 7.76 7.76 0 0 0 1.05-3.47c0-3.6-2.91-6.51-6.51-6.51Zm0 18.2-.66.75h-.01l-.02-.03a13.47 13.47 0 0 1-.32-.29 37.64 37.64 0 0 1-3.52-3.76 24.6 24.6 0 0 1-2.7-3.95 9.7 9.7 0 0 1-1.28-4.4 8.51 8.51 0 0 1 17.02 0 9.7 9.7 0 0 1-1.29 4.4 24.6 24.6 0 0 1-2.69 3.95 40.23 40.23 0 0 1-3.84 4.05l-.02.02-.67-.74Zm0 0 .66.75-.66.59-.66-.6.66-.74Zm0-13.4a1.4 1.4 0 1 0 0 2.8 1.4 1.4 0 0 0 0-2.8ZM8.6 9.6a3.4 3.4 0 1 1 6.8 0 3.4 3.4 0 0 1-6.8 0Z"></path>
                                            </svg>
                                            <span className="mt-1">{t('event.location')}</span>
                                        </div>
                                    </div>
*/}
                                    <div className="col-12 col-md-4 col-lg-auto mb-2">
                                        <AddToCalendarButton
                                            label={t('button.addToCalendar')}
                                            name={event.title}
                                            description={`${APP_URL}/event/${event.organizerUrl}/${event.url}`}
                                            options={['Apple', 'Google', 'iCal', 'Microsoft365', 'MicrosoftTeams', 'Outlook.com', 'Yahoo']}
                                            startDate={startDate}
                                            startTime={startTime}
                                            endDate={endDate}
                                            endTime={endTime}
                                            useUserTZ={true}
                                            customCss="/css/atcb.min.css"
                                            lightMode="dark"
                                            hideCheckmark="true"
                                            buttonStyle="custom"
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="mb-6">
                                <ul className="nav nav-tabs mt-lg-6" id="eventDetails" role="tablist">
                                    <li className="nav-item" role="presentation">
                                        <button
                                            className={`nav-link ${selectedTab === 0 ? 'active' : ''}`}
                                            id="event-details-tab"
                                            data-bs-toggle="tab"
                                            data-bs-target="#event-details-tab-pane"
                                            type="button"
                                            role="tab"
                                            aria-controls="event-details-tab-pane"
                                            aria-selected="true"
                                            onClick={() => {
                                                setSelectedTab(0)
                                            }}
                                        >{t('button.details')}
                                        </button>
                                    </li>
                                    <li className="nav-item" role="presentation">
                                        <button
                                            className={`nav-link ${selectedTab === 1 ? 'active' : ''}`}
                                            id="event-tickets-tab"
                                            data-bs-toggle="tab"
                                            data-bs-target="#event-tickets-tab-pane"
                                            type="button"
                                            role="tab"
                                            aria-controls="event-tickets-tab-pane"
                                            aria-selected="false"
                                            onClick={() => {
                                                setSelectedTab(1)
                                            }}
                                        >
                                            <Trans
                                                i18nKey={'button.ticketsCount'}
                                                components={[
                                                    <span className="d-none d-sm-inline"/>,
                                                    <b className="badge text-bg-success"/>
                                                ]}
                                                count={userTickets?.length || 0}
                                            />
                                            {loadingTickets ?
                                                <span className={'icon-loading-container'} style={{display: 'block'}}>
                                                    <LoadingIcon/>
                                                </span>
                                                :
                                                null
                                            }
                                        </button>
                                    </li>
                                    {event.organizer === walletAddress ?
                                        <li className="nav-item" role="presentation">
                                            <button
                                                className={`nav-link ${selectedTab === 2 ? 'active' : ''}`}
                                                id="event-bot-tab"
                                                data-bs-toggle="tab"
                                                data-bs-target="#event-bot-tab-pane"
                                                type="button"
                                                role="tab"
                                                aria-controls="event-bot-tab-pane"
                                                aria-selected="false"
                                                onClick={() => {
                                                    setSelectedTab(2)
                                                }}
                                            >{t('button.botSettings')}
                                            </button>
                                        </li>
                                        :
                                        null
                                    }
                                </ul>
                                <div className="tab-content">
                                    <div
                                        className={`tab-pane ${selectedTab === 0 ? 'show active' : ''}`}
                                        id="event-details-tab-pane"
                                        role="tabpanel"
                                        aria-labelledby="event-details-tab"
                                        tabIndex={0}
                                    >
                                        <div className="pt-4">
                                            <div className="mb-4">
                                                {event.description?.split(/\r?\n/).map((item, index) => (
                                                    <p key={index}>{item}</p>))}
                                            </div>
                                            <div className="mb-4">
                                                <h3 className="mb-3">
                                                    {event.organizer === walletAddress ?
                                                        t('event.connectedShowcases')
                                                        :
                                                        t('event.buyTicketsHere')
                                                    }
                                                </h3>
                                                <div className="row-table">
                                                    {showcasesList}
                                                </div>
                                                {event.organizer === walletAddress && event.showcases && event.showcases.length < 3 ?
                                                    <ButtonElement
                                                        small
                                                        outline
                                                        additionalClass="mb-4"
                                                        onClick={addShowcaseHandler}
                                                    >
                                                        <AddIcon/>
                                                        <span>{t('button.addShowcase')}</span>
                                                    </ButtonElement>
                                                    :
                                                    null
                                                }
                                            </div>
                                        </div>
                                    </div>
                                    <div
                                        className={`tab-pane ${selectedTab === 1 ? 'show active' : ''}`}
                                        id="event-details-tab-pane"
                                        role="tabpanel"
                                        aria-labelledby="event-details-tab"
                                        tabIndex={1}
                                    >
                                        <div className="pt-4">
                                            <div className="mb-4">
                                                {editingTicketDescription ?
                                                    <>
                                                        <TextareaElement
                                                            value={ticketDescription}
                                                            onChange={setTicketDescription}
                                                            additionalClass={'mb-2'}
                                                            rows={5}
                                                        />
                                                        <ButtonElement
                                                            small
                                                            outline
                                                            onClick={saveTicketDescriptionHandler}
                                                        >
                                                            <span>{t('button.save')}</span>
                                                        </ButtonElement>
                                                    </>
                                                    :
                                                    <>
                                                        <AlertElement additionalClass={'mb-2'}>
                                                            {eventTicketDescription?.split(/\r?\n/).map((item, index) => (
                                                                <p key={index}>{item}</p>
                                                            ))}
                                                        </AlertElement>
                                                        {event.organizer === walletAddress && event.showcases && event.showcases.length < 3 ?
                                                            <ButtonElement
                                                                small
                                                                outline
                                                                onClick={editTicketDescriptionHandler}
                                                            >
                                                                <EditIcon className={'me-2'}/>
                                                                <span>{t('button.editText')}</span>
                                                            </ButtonElement>
                                                            :
                                                            null
                                                        }
                                                    </>
                                                }
                                            </div>
                                        </div>
                                        {walletAddress ?
                                            <div>
                                                <h3 className="mb-3">{t('event.yourTickets')}</h3>
                                                <div className="row-table">
                                                    {ticketsList}
                                                </div>
                                            </div>
                                            :
                                            null
                                        }
                                    </div>
                                    {event.organizer === walletAddress ?
                                        <div
                                            className={`tab-pane ${selectedTab === 2 ? 'show active' : ''}`}
                                            id="event-bot-tab-pane"
                                            role="tabpanel"
                                            aria-labelledby="event-bot-tab"
                                            tabIndex={0}
                                        >
                                            <div className="pt-4">
                                                <div className="d-flex align-items-center mb-4">
                                                    <Trans
                                                        i18nKey={'event.botSettings0'}
                                                        count={event.id}
                                                        components={[
                                                            <span className="text-muted me-2"/>,
                                                            <CopyToClipboardBlock
                                                                text={event.id}
                                                                small
                                                                additionalClass={'mt-n1'}
                                                            />
                                                        ]}
                                                    />
                                                </div>
                                                <div>
                                                    <ol className="ps-3">
                                                        <li className="mb-3">
                                                            <Trans
                                                                i18nKey={'event.botSettings1'}
                                                                components={[
                                                                    <span className="font-monospace badge text-bg-secondary"/>
                                                                ]}
                                                            />
                                                        </li>
                                                        <li className="mb-3">
                                                            <Trans
                                                                i18nKey={'event.botSettings2'}
                                                                components={[
                                                                    <a href={`https://t.me/${TG_AUTH_BOT}`} target="_blank" rel={'noreferrer'}/>
                                                                ]}
                                                            />
                                                        </li>
                                                        <li className="mb-3">
                                                            {t('event.botSettings3')}
                                                        </li>
                                                        <li className="mb-3">
                                                            <Trans
                                                                i18nKey={'event.botSettings4'}
                                                                components={[
                                                                    <span className="font-monospace badge text-bg-secondary"/>,
                                                                    <CopyToClipboardBlock
                                                                        text={'/init'}
                                                                        small
                                                                        additionalClass={'mt-n1'}
                                                                    />
                                                                ]}
                                                            />
                                                        </li>
                                                        <li className="mb-3">
                                                            <Trans
                                                                i18nKey={'event.botSettings5'}
                                                                count={event.id}
                                                                components={[
                                                                    <span className="font-monospace badge text-bg-secondary"/>,
                                                                    <CopyToClipboardBlock
                                                                        text={`/addevent ${event.id}`}
                                                                        small
                                                                        additionalClass={'mt-n1'}
                                                                    />
                                                                ]}
                                                            />
                                                        </li>
                                                    </ol>
                                                </div>
                                            </div>
                                        </div>
                                        :
                                        null
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
            }
        </div>
    </div>
}

export default Event
