import React from 'react'
import {useDispatch, useSelector} from 'react-redux'
import DatePicker from 'react-datepicker'
import {useTranslation} from 'react-i18next'
import {ButtonElement} from './index'
import {ChevronDownIcon, ChevronUpDownIcon, CloseIcon} from '../icons'
import {getDateFilter, getDateFilterAsc, setDateFilter, setDateFilterAsc} from '../../store/appSlice'

const DateFilter = () => {
    const {t} = useTranslation()
    const dateFilter = useSelector(getDateFilter)
    const dateFilterAsc = useSelector(getDateFilterAsc)

    const dispatch = useDispatch()

    const changeHandler = (value: Date | null) => {
        dispatch(setDateFilter(value))
    }
    const sortHandler = () => {
        dispatch(setDateFilterAsc(!dateFilterAsc))
    }

    return <div className="btn-group w-100">
        <DatePicker
            calendarClassName={'btn-group flex-grow-1 dropdown'}
            selected={dateFilter}
            onChange={changeHandler}
            customInput={<ButtonElement
                small
                outline
                additionalClass={'btn-filter-select dropdown-toggle'}
            >
                <span className="me-2">{dateFilter?.toDateString() || t('element.anyDate')}</span>
                {dateFilter ? <CloseIcon notFill onClick={() => {changeHandler(null)}}/> : <ChevronDownIcon/>}
            </ButtonElement>
            }
        />
        <ButtonElement
            small
            outline
            additionalClass={`btn-sort-arrows flex-grow-0 ${dateFilterAsc ? 'sort-up' : 'sort-down'}`}
            onClick={sortHandler}
        >
            <ChevronUpDownIcon/>
        </ButtonElement>
    </div>
}

export default DateFilter
