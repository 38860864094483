import React, {useContext, useRef, useState} from 'react'
import {useSelector} from 'react-redux'
import {useWeb3Onboard} from '@web3-onboard/react/dist/context'
import {useTranslation} from 'react-i18next'
import {CHAINS} from '../../utils/constants'
import {getCurrentNetwork} from '../../store/appSlice'
import {ButtonElement, IDropdownItem} from '../elements'
import {closeDropdown, openDropdown} from '../../utils/functions'
import {ChevronDownIcon, CrossIcon} from '../icons'
import {ThemeContext} from '../../context'

const ChainSelector = () => {
    const {t} = useTranslation()
    const {dark} = useContext(ThemeContext)
    const [cursorOnElement, setCursorOnElement] = useState(false)
    const [elementOpened, setElementOpened] = useState(false)
    const currentNetwork = useSelector(getCurrentNetwork)
    const selectorRef = useRef(null)
    const onboard = useWeb3Onboard()

    let chainList: IDropdownItem[] = []
    let testnetList: IDropdownItem[] = []
    for (let key in CHAINS) {
        if (CHAINS[key].testnet) {
            testnetList.push({id: key, name: CHAINS[key].label})
        } else {
            chainList.push({id: key, name: CHAINS[key].label})
        }
    }
    const changeNetwork = (id: string) => {
        onboard.setChain({chainId: id})
        setElementOpened(false)
    }
    const closeUserMenu = (close?: boolean) => {
        closeDropdown({
            close,
            cursorOnDropdown: cursorOnElement,
            setClosed: () => setElementOpened(false),
            setCursorOnDropdown: () => setCursorOnElement(false),
        })
    }
    const openUserMenu = () => {
        openDropdown({
            closeMenu: closeUserMenu,
            element: selectorRef,
            setOpened: () => setElementOpened(true)
        })
    }

    return <div
        className="s-header__chain me-3 me-md-4"
        ref={selectorRef}
        onClick={openUserMenu}
        onMouseEnter={openUserMenu}
        onMouseLeave={() => {
            closeUserMenu()
        }}
    >
        <ButtonElement outline small additionalClass={'dropdown-toggle'}>
            {currentNetwork ?
                <>
                    <span className="logo">
                        <img src={CHAINS[currentNetwork].icon} alt={CHAINS[currentNetwork].label}/>
                    </span>
                    <span className="name me-2">{CHAINS[currentNetwork].label}</span>
                </>
                :
                <>
                    <span className="logo">
                        <img src={`/img/nets/${dark ? 'dark' : 'light'}-mode/error-network.svg`} alt={t('error.wrongNetwork')}/>
                    </span>
                    <span className="name me-2 text-error">{t('error.wrongNetwork')}</span>
                </>
            }
            <ChevronDownIcon/>
        </ButtonElement>
        {elementOpened ?
            <div
                className="btn-dropdown s-header__chain-dropdown"
                onMouseEnter={() => {
                    setCursorOnElement(true)
                    openUserMenu()
                }}
                onMouseLeave={() => {
                    closeUserMenu(true)
                }}
            >
                <div className="dropdown-wrap">
                    <div className="dropdown-header">
                        <b>{t('form.label.selectChain')}</b>
                        <button className="btn p-2" onClick={() => {
                            setElementOpened(false)
                            setCursorOnElement(false)
                        }}>
                            <CrossIcon/>
                        </button>
                    </div>
                    <div className="scroll">
                        <ul>
                            {chainList.map((item, index) => <li key={index}>
                                <button className="item" onClick={() => changeNetwork(item.id)}>
                                    <span className="logo"><img src={CHAINS[item.id].icon} alt={item.name}/></span>
                                    <span className="name">{item.name}</span>
                                </button>
                            </li>)}
                        </ul>
                        <ul>
                            {testnetList.map((item, index) => <li key={index}>
                                <button className="item" onClick={() => changeNetwork(item.id)}>
                                    <span className="logo"><img src={CHAINS[item.id].icon} alt={item.name}/></span>
                                    <span className="name">{item.name}</span>
                                </button>
                            </li>)}
                        </ul>
                    </div>
                </div>
            </div>
            :
            null
        }
    </div>
}

export default ChainSelector
