import {createAsyncThunk, createSelector, createSlice, PayloadAction} from '@reduxjs/toolkit'
import {ethers} from 'ethers'
import {AbiItem} from 'web3-utils'
import {RootState} from './store'
import {CHAINS, CUSTOM_DROPDOWN_ITEM, ERC20_TOKENS, NULL_ADDRESS} from '../utils/constants'
import Erc20Abi from '../utils/abi/erc20.json'
import {ICoin, ICoinObject} from './types'
import {IDropdownItem} from '../components/elements'

interface CoinsState {
    coins: ICoin[] | null
}

const initialState: CoinsState = {
    coins: null,
}

export const requestCoins = createAsyncThunk(
    'coins/requestCoins',
    async (network: string | undefined, {dispatch, getState}): Promise<undefined> => {
        const state = getState() as RootState
        const {currentNetwork} = state.app

        const net = network || currentNetwork
        if (!net) {
            return
        }

        for (let item of ERC20_TOKENS[net] || []) {
            dispatch(requestCoin({address: item, network: net}))
        }
    }
)
export const requestCoin = createAsyncThunk(
    'coins/requestCoin',
    async (params: {address: string, network: string | null}, {getState}): Promise<ICoin | null> => {
        const {address, network} = params
        const state = getState() as RootState
        const {currentNetwork, web3} = state.app

        if (!ethers.utils.isAddress(address)) {
            return null
        }

        if (currentNetwork && web3 && currentNetwork === network) {
            try {
                const contract = new web3.eth.Contract(Erc20Abi as AbiItem[], address)
                const symbol = await contract.methods.symbol().call()
                const decimals = Number(await contract.methods.decimals().call())
                return {contract: address.toLowerCase(), token: symbol, decimals}
            } catch (e) {
                console.log(e)
                return null
            }
        } else if (network) {
            try {
                const provider = ethers.getDefaultProvider(CHAINS[network].rpcUrl)
                const contract = new ethers.Contract(
                    address,
                    new ethers.utils.Interface(JSON.stringify(Erc20Abi)),
                    provider
                )
                const symbol = await contract.symbol()
                const decimals = await contract.decimals()
                return {contract: address.toLowerCase(), token: symbol, decimals}
            } catch (e) {
                console.log(e)
            }
        }
        return null
    }
)
export const requestCustomCoins = createAsyncThunk(
    'coins/requestCustomCoins',
    async (network: string | null, {dispatch, getState}): Promise<undefined> => {
        const state = getState() as RootState
        const {showcase} = state.showcaseV2

        if (!showcase) {
            return
        }

        const coinObjects = getCoinsObject(state)
        let requested: string[] = []
        for (let item of showcase.items) {
            for (let price of item.prices) {
                if (coinObjects[price.payWith] === undefined && requested.indexOf(price.payWith) < 0) {
                    dispatch(requestCoin({address: price.payWith, network}))
                    requested.push(price.payWith)
                }
            }
        }
    }
)

export const coinsSlice = createSlice({
    name: 'coins',
    initialState,
    reducers: {
        setCoins: (state, action: PayloadAction<ICoin[] | null>) => {
            state.coins = action.payload
        },
    },
    extraReducers: (builder) => {
        builder.addCase(requestCoin.fulfilled, (state, action: PayloadAction<ICoin | null>) => {
            if (action.payload) {
                if (state.coins) {
                    let newCoins = [...state.coins]
                    let found = false
                    for (let item of newCoins) {
                        if (item.contract === action.payload.contract) {
                            found = true
                            break
                        }
                    }
                    if (!found) {
                        newCoins.push(action.payload)
                    }
                    state.coins = newCoins
                } else {
                    state.coins = [action.payload]
                }
            }
        })
    },
})

export const getCoins = (state: RootState): ICoin[] | null => state.coins.coins
export const getCoinsObject = createSelector(
    (state: RootState) => state.coins.coins,
    (state: RootState) => state.app.currentNetwork,
    (coins, currentNetwork): ICoinObject => {
        let coinObjects: ICoinObject = {}
        if (currentNetwork) {
            coinObjects[NULL_ADDRESS] = {
                contract: NULL_ADDRESS,
                token: CHAINS[currentNetwork].token,
                decimals: CHAINS[currentNetwork].tokenPrecision,
            }
        }
        for (let item of coins || []) {
            coinObjects[item.contract] = item
        }
        return coinObjects
    }
)
export const getStandardCoinsList = createSelector(
    (state: RootState) => state.coins.coins,
    (state: RootState) => state.app.currentNetwork,
    (coins, currentNetwork): IDropdownItem[] => {
        let coinsList: IDropdownItem[] = []
        if (currentNetwork) {
            for (let item of coins || []) {
                if (ERC20_TOKENS[currentNetwork].indexOf(item.contract) >= 0) {
                    coinsList.push({id: item.contract, name: item.token})
                }
            }
            coinsList.push({id: NULL_ADDRESS, name: CHAINS[currentNetwork].token})
            coinsList.push(CUSTOM_DROPDOWN_ITEM)
        }
        return coinsList
    }
)
export const {
    setCoins,
} = coinsSlice.actions

export default coinsSlice.reducer
