import React, {useEffect} from 'react'
import {Link} from 'react-router-dom'
import {useDispatch} from 'react-redux'
import {Trans, useTranslation} from 'react-i18next'
import {setMainPageClass} from '../../store/appSlice'

const About = () => {
    const {t} = useTranslation()
    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(setMainPageClass('page-home'))
        return () => {
            dispatch(setMainPageClass(null))
        }
    }, [])

    return <div className="page-content">
        <svg className="bg-home" width="706" height="745" viewBox="0 0 706 745" fill="none"
             xmlns="http://www.w3.org/2000/svg">
            <path d="m107.43.61 597.62 160.13L598.23 559.4.61 399.26z"></path>
            <path d="m502.02 321.61 92.13 343.81-293.41 78.62-92.13-343.81z"></path>
            <path d="M.6 160.74 598.24.61l106.81 398.65-597.61 160.13z"></path>
        </svg>
        <div className="container">
            <div className="row">
                <div className="col-lg-6">
                    <h1 className="h1-intro mb-3">
                        <Trans i18nKey={'about.text1'} components={[<span className="text-primary"/>]}/>
                    </h1>
                    <div className="d-flex flex-wrap mb-3 mb-lg-5">
                        <Link className="badge rounded-pill me-2 px-3 py-2 my-1"
                              to="/classic/mint">{t('about.button.tickets')}</Link>
                        <Link className="badge rounded-pill me-2 px-3 py-2 my-1"
                              to="/accesses/tgbot">{t('about.button.accesses')}</Link>
                        <Link className="badge rounded-pill me-2 px-3 py-2 my-1"
                              to="/gifts">{t('about.button.gifts')}</Link>
                        <Link className="badge rounded-pill me-2 px-3 py-2 my-1"
                              to="/sbt">{t('about.button.certificates')}</Link>
                    </div>
                    <div className="video-container d-lg-none mb-4">
                        <iframe width="560" height="315"
                                src="https://www.youtube.com/embed/9-bjYLAGPtU?si=_IkW8-gkFoFFUxMM"
                                title="YouTube video player"
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"></iframe>
                    </div>
                    <div className="row">
                        <div className="col-lg-10">
                            <div className="mb-4">
                                <p>
                                    <Trans
                                        i18nKey={'about.text2'}
                                        components={[
                                            <a
                                                href={'https://hackernoon.com/nft-20-the-era-of-programmable-assets'}
                                                target={'_blank'}
                                                rel={'noreferrer'}
                                            />
                                        ]}
                                    />
                                </p>
                            </div>
                            <Link to={'/classic/mint'} className="btn btn-primary">{t('about.tryForFree')}</Link>
                            <div className="mt-6 text-muted">
                                {t('about.disclaimer')}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-lg-6">
                    <div className="video-container d-none d-lg-block">
                        <iframe width="560" height="315"
                                src="https://www.youtube.com/embed/9-bjYLAGPtU?si=_IkW8-gkFoFFUxMM"
                                title="YouTube video player"
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"></iframe>
                    </div>
                </div>
            </div>
        </div>
    </div>
}

export default About
