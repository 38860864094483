import React, {useRef, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {useTranslation} from 'react-i18next'
import {setModalAddProperties} from '../../store/appSlice'
import {ButtonElement, InputElement} from '../elements'
import {CloseIcon} from '../icons'
import {getProperties, setProperties, setPropertiesError} from '../../store/inputSlice'
import {IProperty} from '../../store/types'

interface propsType {
    show: boolean
}

const ModalAddProperties = (props: propsType) => {
    const {t} = useTranslation()
    const modalFade = useRef(null)
    const properties = useSelector(getProperties)
    const [nftPropertiesDataTmp, setNftPropertiesDataTmp] = useState<IProperty[]>(properties.value)

    const dispatch = useDispatch()

    const addEmptyProperty = () => {
        setNftPropertiesDataTmp((prevState) => {
            let newState = [...prevState]
            newState.push({trait_type: '', value: ''})
            return newState
        })
    }
    const changeNftProperties = (index: number, type?: string, value?: string) => {
        setNftPropertiesDataTmp((prevState) => {
            let newState = [...prevState]
            newState[index] = {...prevState[index]}
            if (type !== undefined) {
                newState[index].trait_type = type
            }
            if (value !== undefined) {
                newState[index].value = value
            }
            return newState
        })
    }
    const closeModal = () => {
        dispatch(setModalAddProperties(false))
    }
    const deleteNftProperties = (index: number) => {
        setNftPropertiesDataTmp((prevState) => {
            let newState = [...prevState]
            newState.splice(index, 1)
            return newState
        })
    }
    const saveNftProperties = () => {
        let error = false
        let prop: IProperty[] = []
        for (let item of nftPropertiesDataTmp) {
            if (item.trait_type !== '') {
                prop.push(item)
                if (item.value === '') {
                    error = true
                }
            }
        }
        dispatch(setProperties(prop))
        if (error) {
            dispatch(setPropertiesError(t('error.enterAllProperties')))
        } else {
            dispatch(setPropertiesError(null))
        }
    }

    if (!props.show) {
        return null
    }

    return <div
        className={`modal modal-lg fade show`}
        ref={modalFade}
        tabIndex={-1}
        aria-labelledby="Add properties"
        aria-hidden="true"
        style={{display: 'block'}}
        onMouseDown={(e) => {
            if (e.target === modalFade.current) {
                closeModal()
            }
        }}
    >
        <div className="modal-dialog">
            <div className="modal-content">
                <div className="modal-header pb-3">
                    <h2 className="modal-title">{t('modal.title.addProperties')}</h2>
                    <button className="btn p-2 btn-with-opacity btn-modal-close" onClick={closeModal}>
                        <CloseIcon/>
                    </button>
                </div>
                <div className="modal-body pb-4">
                    <div className="row gx-2 gx-lg-3">
                        <div className="col-6 col-lg-4">
                            <label className="form-label">{t('form.label.property')}</label>
                        </div>
                        <div className="col-6 col-lg-6">
                            <label className="form-label">{t('form.label.value')}</label>
                        </div>
                    </div>
                    {nftPropertiesDataTmp?.map((data, index) => {
                        const {trait_type, value} = data
                        return <div className="row gx-2 gx-lg-3" key={index}>
                            <div className="col-6 col-lg-4">
                                <div className="mb-lg-3">
                                    <InputElement
                                        value={trait_type}
                                        placeholder={t('form.label.name')}
                                        onChange={(value) => {
                                            changeNftProperties(index, value)
                                        }}
                                    />
                                </div>
                            </div>
                            <div className="col-6 col-lg-6">
                                <div className="mb-lg-3">
                                    <InputElement
                                        value={value}
                                        placeholder={t('form.label.value')}
                                        onChange={(value) => {
                                            changeNftProperties(index, undefined, value)
                                        }}
                                    />
                                </div>
                            </div>
                            <div className="col-auto col-lg-2">
                                <div className="mb-2 mb-lg-3">
                                    <button
                                        className="btn btn-link link-danger w-100 px-0"
                                        onClick={() => {
                                            deleteNftProperties(index)
                                        }}
                                    >{t('button.delete')}
                                    </button>
                                </div>
                            </div>
                        </div>
                    })}
                    <ButtonElement link additionalClass="px-0" onClick={addEmptyProperty}>
                        {t('button.addProperty')}
                    </ButtonElement>
                </div>
                <div className="modal-footer justify-content-between">
                    <div className="col-12 col-md pe-4">
                        <p className="my-2 text-muted">{t('modal.propertiesDescription')}</p>
                    </div>
                    <div className="col-12 col-md-auto">
                        <ButtonElement
                            additionalClass="w-100"
                            onClick={() => {
                                saveNftProperties()
                                closeModal()
                            }}
                        >{t('button.saveProperties')}</ButtonElement>
                    </div>
                </div>
            </div>
        </div>
    </div>
}

export default ModalAddProperties
