import React, {useContext} from 'react'
import {IconProps} from '../../store/types'
import {ThemeContext} from '../../context'

const CloseIcon = (props: IconProps) => {
    const {dark} = useContext(ThemeContext)

    return <svg
        className={props.className}
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        onClick={props.onClick}
    >
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M5.3 5.3a1 1 0 0 1 1.4 0l5.3 5.29 5.3-5.3a1 1 0 1 1 1.4 1.42L13.42 12l5.3 5.3a1 1 0 0 1-1.42 1.4L12 13.42l-5.3 5.3a1 1 0 0 1-1.4-1.42L10.58 12l-5.3-5.3a1 1 0 0 1 0-1.4Z"
            fill={props.notFill ? undefined : dark ? '#eeeeee' : '#000000'}
        ></path>
    </svg>
}

export default CloseIcon
