import {createAsyncThunk, createSlice, PayloadAction} from '@reduxjs/toolkit'
import axios from 'axios'
import i18next from 'i18next'
import {API_URL} from '../utils/constants'
import {RootState} from './store'
import {SliceResponse} from './types'
import {checkResponse, setModalError} from './appSlice'

interface ProfileState {
    profile: DBProfile | null
}

interface DBProfile {
    telegramId: bigint
}

const initialState: ProfileState = {
    profile: null,
}

export const postUserTelegramId = createAsyncThunk(
    'profile/postUserTelegramId',
    async (telegram: string, {dispatch, getState}): Promise<void> => {
        const state = getState() as RootState
        const {jwt} = state.auth

        let response: SliceResponse = {}
        if (!jwt || telegram === '') {
            response.error = {text: i18next.t('error.jwtOrTelegramNotFound')}
        } else {
            try {
                const config: any = {headers: {'authorization': `Bearer ${jwt}`}}
                const body = {telegram}
                const result = await axios.post(`${API_URL}profile/telegram`, body, config)
                response.status = result.status
            } catch (e: any) {
                if (e.response) {
                    response.status = e.response.status
                    response.error = {text: e.response.data.error}
                } else {
                    response.error = {text: e.message}
                }
            }
        }
        response.afterCheckCallback = () => {
            dispatch(requestProfile())
            dispatch(setModalError({
                title: i18next.t('modal.title.success'),
                text: i18next.t('alert.telegramSaved'),
                buttons: ['close']
            }))
        }
        dispatch(checkResponse(response))
    }
)
export const requestProfile = createAsyncThunk(
    'profile/requestProfile',
    async (_, {getState, dispatch}): Promise<void> => {
        const state = getState() as RootState
        const {jwt, user} = state.auth

        let response: SliceResponse = {}
        if (!jwt || !user) {
            response.error = {text: i18next.t('error.notAuthorized')}
        } else {
            try {
                const config: any = {headers: {'authorization': `Bearer ${jwt}`}}
                const result = await axios.get(`${API_URL}profile`, config)
                response.status = result.status
                response.data = result.data.profile
            } catch (e: any) {
                response.defaultData = null
                if (e.response) {
                    response.status = e.response.status
                    response.error = {text: e.response.data.error}
                } else {
                    response.error = {text: e.message}
                }
            }
        }
        response.setData = (value) => {
            dispatch(setProfile(value))
        }
        dispatch(checkResponse(response))
    }
)

export const profileSlice = createSlice({
    name: 'profile',
    initialState,
    reducers: {
        setProfile: (state, action: PayloadAction<DBProfile | null>) => {
            state.profile = action.payload
        },
    },
})

export const getProfile = (state: RootState): DBProfile | null => state.profile.profile

export const {
    setProfile,
} = profileSlice.actions

export default profileSlice.reducer
