import React from 'react'
import {useSelector} from 'react-redux'
import {NavLink} from 'react-router-dom'
import {useTranslation} from 'react-i18next'
import {CHAINS} from '../../utils/constants'
import {DBEventWithOrganizer, ITicket} from '../../store/types'
import {ExternalLinkSmallIcon} from '../icons'
import {getToken} from '../../store/tokensSlice'

interface PropsType {
    event: DBEventWithOrganizer | null
    item: ITicket
}

const TicketDescription = ({event, item}: PropsType) => {
    const {t} = useTranslation()
    const token = useSelector(getToken(item.contract, item.tokenId))

    return <div className="row pt-4 pb-2">
        <div className="col-md-7 col-xl-4 ps-xl-0">
            <div className="row mb-2 gx-3">
                <div className="col-lg-3">
                    <div className="text-muted text-lg-end">{t('form.label.address')}</div>
                </div>
                <div className="col-lg-9 text-break">{item.contract}</div>
            </div>
            <div className="row mb-2 gx-3">
                <div className="col-lg-3">
                    <div className="text-muted text-lg-end">ID</div>
                </div>
                <div className="col-lg-9 text-break">{item.tokenId.toString()}</div>
            </div>
            <div className="row mb-2 gx-3">
                <div className="col-lg-3">
                    <div className="text-muted text-lg-end">{t('form.label.network')}</div>
                </div>
                <div className="col-lg-9">{CHAINS[`0x${Number(item.network).toString(16)}`]?.label}</div>
            </div>
            {/*
            <div className="row mb-2 gx-3">
                <div className="col-lg-3">
                    <div className="text-muted text-lg-end">{t('from.label.showcase')}</div>
                </div>
                <div className="col-lg-9">Showcase name</div>
            </div>
*/}
        </div>
        <div className="col-md-5 col-xl-4 ps-xl-0">
            <div className="row mb-2 gx-3">
                <div className="col-lg-4">
                    <div className="text-muted text-lg-end">{t('form.label.organizer')}</div>
                </div>
                <div className="col-lg-8 text-break">{event?.organizerTitle || '-'}</div>
            </div>
            <div className="row mb-2 gx-3">
                <div className="col-lg-4">
                    <div className="text-muted text-lg-end">{t('form.label.event')}</div>
                </div>
                {event ?
                    <div className="col-lg-8">
                        <NavLink to={`/event/${event.organizerUrl}/${event.url}`}>{event.title}</NavLink>
                    </div>
                    :
                    '-'
                }
            </div>
            <div className="row mb-2 gx-3">
                <div className="col-lg-4">
                    <div className="text-muted text-lg-end">{t('form.label.ticket')}</div>
                </div>
                <div className="col-lg-8">{token?.name || '-'}</div>
            </div>
            <div className="row mb-2 gx-3">
                <div className="col-lg-4">
                    <div className="text-muted text-lg-end">{t('form.label.level')}</div>
                </div>
                <div className="col-lg-8">
                    {item.level ? `${item.level.title} (${item.level.level})` : '-'}
                </div>
            </div>
        </div>
        <div className="col-lg-7 col-xl-4 mt-3 mt-xl-0 ps-xl-0">
            <div className="row mb-2 gx-3">
                <div className="col-lg-3">
                    <div className="text-muted text-lg-end">{t('form.label.about')}</div>
                </div>
                <div className="col-lg-9">
{/*
                    <div className="mb-2">
                        Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been
                        the industry's standard dummy text ever since the 1500s.
                    </div>
*/}
                    <div className="d-flex d-lg-none align-items-center">
                        {item.tokenUri ?
                            <a href={item.tokenUri} target={'_blank'} rel={'noreferrer'} className={'icon-link'}>
                                JSON
                            </a>
                            :
                            null
                        }
                        <a className="icon-link" href="#">
                            <span className="text-white">12 oct 2023 11:00</span>
                            <ExternalLinkSmallIcon/>
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </div>
}

export default TicketDescription
