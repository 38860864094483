import React, {useEffect} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {useTranslation} from 'react-i18next'
import {AppDispatch} from '../../store/store'
import {
    getDescription,
    getTitle,
    setDescription,
    setDescriptionError,
    setTitle,
    setTitleError
} from '../../store/inputSlice'
import {InputElement, TextareaElement} from '../elements'

interface PropsType {
    titleLabel: string
    descriptionLabel: string
}

const TitleAndDescriptionBlock = (props: PropsType) => {
    const {t} = useTranslation()
    const description = useSelector(getDescription)
    const title = useSelector(getTitle)

    const dispatch = useDispatch<AppDispatch>()

    useEffect(() => {
        return () => {
            dispatch(setTitle(''))
            dispatch(setDescription(''))
        }
    }, [])

    const onChangeDescription = (value: string) => {
        if (value === '') {
            dispatch(setDescriptionError(t('error.mustEnter', {name: props.descriptionLabel})))
        } else if (value.length < 3) {
            dispatch(setDescriptionError(t('error.mustBeGreater', {name: props.descriptionLabel, value: 2})))
        } else {
            dispatch(setDescriptionError(null))
        }
        dispatch(setDescription(value))
    }
    const onChangeTitle = (value: string) => {
        if (value === '') {
            dispatch(setTitleError(t('error.mustEnter', {name: props.titleLabel})))
        } else {
            dispatch(setTitleError(null))
        }
        dispatch(setTitle(value))
    }

    return <div className="row mb-3">
        <div className="col-lg-8">
            <div className="mb-4">
                <InputElement
                    error={title.error.status}
                    errorText={title.error.text}
                    label={<>{props.titleLabel} <span className="text-danger">*</span></>}
                    value={title.value}
                    onChange={onChangeTitle}
                />
            </div>
            <div className="mb-4">
                <TextareaElement
                    error={description.error.status}
                    errorText={description.error.text}
                    label={<>{props.descriptionLabel} <span className="text-danger">*</span></>}
                    value={description.value}
                    onChange={onChangeDescription}
                    rows={5}
                />
            </div>
        </div>
    </div>
}

export default TitleAndDescriptionBlock
