import {createAsyncThunk, createSlice, PayloadAction} from '@reduxjs/toolkit'
import {ethers} from 'ethers'
import {AbiItem} from 'web3-utils'
import {RootState} from './store'
import {IBalance, IBalances} from './types'
import Erc20Abi from '../utils/abi/erc20.json'

interface UserState {
    balances: IBalances,
}

const initialState: UserState = {
    balances: {},
}

export const requestBalance = createAsyncThunk(
    'user/requestBalance',
    async (coinAddress: string, {getState}): Promise<IBalance | null> => {
        const state = getState() as RootState
        const {currentNetwork, walletAddress, web3} = state.app

        if (!ethers.utils.isAddress(coinAddress)) {
            return null
        }

        if (currentNetwork && walletAddress && web3) {
            try {
                const contract = new web3.eth.Contract(Erc20Abi as AbiItem[], coinAddress)
                const symbol = await contract.methods.symbol().call()
                const decimals = Number(await contract.methods.decimals().call())
                const units = await contract.methods.balanceOf(walletAddress).call()
                const balance = ethers.utils.formatUnits(units, decimals)
                return {contract: coinAddress.toLowerCase(), token: symbol, decimals, balance, units}
            } catch (e) {
                console.log(e)
                return null
            }
        }
        return null
    }
)

export const userSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {
        setBalances: (state, action: PayloadAction<IBalances>) => {
            state.balances = action.payload
        },
    },
    extraReducers: (builder) => {
        builder.addCase(requestBalance.fulfilled, (state, action: PayloadAction<IBalance | null>) => {
            if (action.payload) {
                state.balances[action.payload.contract] = action.payload
            }
        })
    },
})

export const getBalances = (state: RootState): IBalances => state.user.balances

export const {
    setBalances,
} = userSlice.actions

export default userSlice.reducer
