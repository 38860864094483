import React from 'react'
import {LabelElement} from './index'

interface IProps {
    label?: string
    title: string
}

const DropdownPlaceholder = (props: IProps) => {
    return <>
        {props.label ? <LabelElement>{props.label}</LabelElement> : null}
        <div className="form-control disabled">
            <div className="value placeholder">{props.title}</div>
        </div>
    </>
}

export default DropdownPlaceholder
