import React from 'react'
import {IconProps} from '../../store/types'

const SmartIcon = (props: IconProps) => {
    return <svg className={props.className} viewBox="0 0 20 20" width="20" height="20" fill="none"
                xmlns="http://www.w3.org/2000/svg">
        <path fillRule="evenodd" clipRule="evenodd"
              d="M2.5 10a7.5 7.5 0 1 0 15 0 7.5 7.5 0 0 0-15 0ZM10 .83a9.17 9.17 0 1 0 0 18.34A9.17 9.17 0 0 0 10 .83Z"></path>
    </svg>
}

export default SmartIcon
