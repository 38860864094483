import React from 'react'
import {CrossIcon} from '../icons'
import {IProperty} from '../../store/types'

interface PropsType {
    property: IProperty
    delHandler: (index: number) => void
    index: number
}

const PropertyRow = (props: PropsType) => {
    const {property, delHandler, index} = props

    return <div className="row-table__item mb-2" key={index}>
        <div className="row align-items-center">
            <div className="mb-2 col-md-4"><b>{property.trait_type}</b></div>
            <div className="mb-2 col-md-7">{property.value}</div>
            <button
                className="btn p-2 btn-with-opacity btn-item-delete"
                onClick={() => {
                    delHandler(index)
                }}
            >
                <CrossIcon/>
            </button>
        </div>
    </div>
}

export default PropertyRow
