import {createAsyncThunk, createSlice, PayloadAction} from '@reduxjs/toolkit'
import {ThirdwebStorage} from '@thirdweb-dev/storage'
import type {Contract} from 'web3-eth-contract'
import axios from 'axios'
import {ethers} from 'ethers'
import i18next from 'i18next'
import {RootState} from './store'
import {
    _AssetType,
    API_URL,
    CHAINS,
    MINT_FACTORY_BASE_URI,
    THIRDWEB_CLIENT_ID,
    THIRDWEB_SECRET_KEY
} from '../utils/constants'
import {
    checkResponse, sendRequestWithAuth,
    setModalCreateMintCollection,
    setModalError,
    setModalMintTickets,
    setModalSendTransactions, setSelectedMintCollection
} from './appSlice'
import {ICollection, initInputData, InputData, IProperty, ISendTransaction, SliceResponse, TShowcaseType} from './types'
import {getSelectedEventName, postEvent} from './eventsSlice'
import {getSelectedOrganizerName} from './organizersSlice'

interface MintState {
    collections: ICollection[] | null
    mintContract: Contract | null
    mintedTokenId: number[] | null
    nftImageUrl: string | null
    nftJsonUrl: string | null
    nftTxId: string | null
    nftTxSigned: boolean
    ticketBatch: InputData<number | null>
    ticketDescription: InputData<string>
    ticketName: InputData<string>
    whitelistedTickets: IWhiteListedTickets | null
}

interface IWhiteListedTickets {
    added: number,
    exist: number,
    errors: number
}

interface TicketRequest {
    chain: number
    contract: string
    tokenId: number
    levelId: number
    assetType: number
}

const initialState: MintState = {
    collections: null,
    mintContract: null,
    mintedTokenId: null,
    nftImageUrl: null,
    nftJsonUrl: null,
    nftTxId: null,
    nftTxSigned: false,
    ticketBatch: initInputData(1),
    ticketDescription: initInputData(''),
    ticketName: initInputData(''),
    whitelistedTickets: null,
}

export const createMintCollection = createAsyncThunk(
    'mint/createMintCollection',
    async ({name, symbol}: { name: string, symbol: string }, {dispatch, getState}): Promise<boolean> => {
        const state = getState() as RootState
        const {currentNetwork, walletAddress, web3} = state.app

        if (!currentNetwork || !web3 || !walletAddress) {
            return false
        }

        let transactions: ISendTransaction[] = []
        const contract = new web3.eth.Contract(CHAINS[currentNetwork].nftFactoryContract721Abi, CHAINS[currentNetwork].nftFactoryContract721)
        const method = contract.methods.deployNewCollection(
            CHAINS[currentNetwork].nftFactoryImplContract721,
            walletAddress,
            name,
            symbol,
            MINT_FACTORY_BASE_URI
        )
        const encodedABI = method.encodeABI()
        transactions.push({
            trx: {
                from: walletAddress,
                to: CHAINS[currentNetwork].nftFactoryContract721,
                data: encodedABI,
            },
            title: i18next.t('action.createCollection'),
            successfulSendingCallback: (receipt) => {
                dispatch(setModalCreateMintCollection(false))
                dispatch(requestMintCollections())
                if (receipt?.logs[0].address) {
                    dispatch(setSelectedMintCollection(receipt.logs[0].address.toLowerCase()))
                }
                dispatch(sendRequestWithAuth(postEvent({name: `${name} event`})))
            }
        })
        dispatch(setModalSendTransactions({transactions}))
        return true
    }
)
export const mintNft = createAsyncThunk(
    'mint/mintNft',
    async (_, {dispatch, getState}): Promise<boolean> => {
        const showError = (title: string, text: string) => {
            dispatch(setModalError({title, text, buttons: ['close']}))
            dispatch(setModalMintTickets(false))
            dispatch(resetMintState())
        }

        const state = getState() as RootState
        const {
            mintContract,
            ticketBatch,
            ticketDescription,
            ticketName,
        } = state.mint
        const {properties} = state.input
        const {currentNetwork, externalUrl, loadedImage, signer, walletAddress, web3} = state.app
        const {ticketLevelId} = state.tickets
        const eventName = getSelectedEventName(state)
        const organizerName = getSelectedOrganizerName(state)
        if (!currentNetwork || !signer || !web3 || !mintContract || !walletAddress || !ticketBatch.value ||
            eventName === '' || organizerName === '' || ticketLevelId === null) {
            return false
        }

        dispatch(setModalMintTickets(true))
        const storage = new ThirdwebStorage({
            clientId: THIRDWEB_CLIENT_ID,
            secretKey: THIRDWEB_SECRET_KEY,
        })

        //loading image to ipfs or getting already loaded image
        let imageIpfsLink
        if (externalUrl !== '' && loadedImage) {
            imageIpfsLink = externalUrl
        } else if (loadedImage) {
            const upload = await storage.upload(loadedImage)
            const url = storage.resolveScheme(upload)
            imageIpfsLink = `ipfs://${url.substring(url.indexOf('/ipfs/') + 6)}`
        } else {
            showError(i18next.t('error.imageNotSelected'), i18next.t('error.imageNotSelectedText'))
            return false
        }
        dispatch(setNftImageUrl(imageIpfsLink))

        const attributes: IProperty[] = [
            {trait_type: i18next.t('form.label.organizerName'), value: organizerName},
            {trait_type: i18next.t('form.label.eventTitle'), value: eventName},
            ...properties.value
        ]
        const upload = await storage.upload(JSON.stringify({
            name: ticketName.value,
            description: ticketDescription.value,
            image: imageIpfsLink,
            attributes,
        }))
        const url = storage.resolveScheme(upload)
        const jsonIpfsLink = `ipfs://${url.substring(url.indexOf('/ipfs/') + 6)}`
        dispatch(setNftJsonUrl(jsonIpfsLink))


        let addresses = []
        let tokenURIs = []
        for (let i = 0; i < ticketBatch.value; i++) {
            addresses.push(walletAddress)
            tokenURIs.push(jsonIpfsLink)
        }
        let mintedTokenIds: number[] = []
        try {
            const mintMethod = ticketBatch.value > 1 ?
                mintContract.methods.mintWithURIBatch(addresses, tokenURIs)
                :
                mintContract.methods.mintWithURI(walletAddress, jsonIpfsLink)
            const encodedABI = mintMethod.encodeABI()
            console.log(`sending transaction to ${CHAINS[currentNetwork].nftMinterContract721}`)
            const tx = await signer.sendTransaction({
                from: walletAddress,
                to: CHAINS[currentNetwork].nftMinterContract721,
                data: encodedABI,
            })
            dispatch(setNftTxSigned(true))

            try {
                const receipt = await tx.wait()
                if (receipt && receipt.status === 1) {
                    console.log(receipt)
                    for (let log of receipt.logs) {
                        for (let item of CHAINS[currentNetwork].nftMinterContract721Abi) {
                            if (item.type !== 'event') {
                                continue
                            }
                            if (item.name !== 'Transfer') {
                                continue
                            }
                            const signature = item.name + '(' + item.inputs?.map((input) => input.type).join(',') + ')'
                            const hash = web3?.utils.sha3(signature)
                            if (hash === log.topics[0]) {
                                mintedTokenIds.push(Number(log.topics[3]))
                                break
                            }
                        }
                    }
                    dispatch(setNftTxId(receipt.transactionHash))
                }
            } catch (error: any) {
                console.log(error.receipt)
                showError(i18next.t('error.mintError'), i18next.t('error.mintErrorText'))
                return false
            }
        } catch (e: any) {
            const error = e.message || e.toString()
            console.log(e)
            showError(i18next.t('error.mintError'), error)
            return false
        }
        if (mintedTokenIds.length > 0) {
            dispatch(setMintedTokenId(mintedTokenIds))
            let tickets: TicketRequest[] = []
            for (let id of mintedTokenIds) {
                tickets.push({
                    chain: Number(currentNetwork),
                    contract: CHAINS[currentNetwork].nftMinterContract721,
                    tokenId: id,
                    assetType: _AssetType.ERC721,
                    levelId: ticketLevelId,
                })
            }
            dispatch(sendRequestWithAuth(sendTicketsToWhitelist(tickets)))
        }
        return true
    }
)
export const mintNftInCollection = createAsyncThunk(
    'mint/mintNftInCollection',
    async (showcaseType: TShowcaseType, {dispatch, getState}): Promise<boolean> => {
        const showError = (title: string, text: string) => {
            dispatch(setModalError({title, text, buttons: ['close']}))
            dispatch(setModalMintTickets(false))
            dispatch(resetMintState())
        }

        const state = getState() as RootState
        const {
            ticketBatch,
            ticketDescription,
            ticketName,
        } = state.mint
        const {properties} = state.input
        const {
            currentNetwork,
            externalUrl,
            loadedImage,
            selectedMintCollection,
            signer,
            walletAddress,
            web3,
        } = state.app
        const {ticketLevelId} = state.tickets
        const eventName = getSelectedEventName(state)
        const organizerName = getSelectedOrganizerName(state)
        if (!currentNetwork || !signer || !web3 || !walletAddress || !ticketBatch.value || eventName === '' ||
            organizerName === '' || ticketLevelId === null || selectedMintCollection === null || !ethers.utils.isAddress(selectedMintCollection)) {
            return false
        }

        dispatch(setModalMintTickets(true))
        const storage = new ThirdwebStorage({
            clientId: THIRDWEB_CLIENT_ID,
            secretKey: THIRDWEB_SECRET_KEY,
        })

        //loading image to ipfs or getting already loaded image
        let imageIpfsLink
        if (externalUrl !== '' && loadedImage) {
            imageIpfsLink = externalUrl
        } else if (loadedImage) {
            const upload = await storage.upload(loadedImage)
            const url = storage.resolveScheme(upload)
            imageIpfsLink = `ipfs://${url.substring(url.indexOf('/ipfs/') + 6)}`
        } else {
            showError(i18next.t('error.imageNotSelected'), i18next.t('error.imageNotSelectedText'))
            return false
        }
        dispatch(setNftImageUrl(imageIpfsLink))

        const attributes: IProperty[] = [
            {trait_type: i18next.t('form.label.organizerName'), value: organizerName},
            {trait_type: i18next.t('form.label.eventTitle'), value: eventName},
            ...properties.value
        ]
        const upload = await storage.upload(JSON.stringify({
            name: ticketName.value,
            description: ticketDescription.value,
            image: imageIpfsLink,
            attributes,
        }))
        const url = storage.resolveScheme(upload)
        const jsonIpfsLink = `ipfs://${url.substring(url.indexOf('/ipfs/') + 6)}`
        dispatch(setNftJsonUrl(jsonIpfsLink))

        let addresses = []
        let tokenURIs = []
        for (let i = 0; i < ticketBatch.value; i++) {
            addresses.push(walletAddress)
            tokenURIs.push(jsonIpfsLink)
        }
        let mintedTokenIds: number[] = []
        const mintContract = new web3.eth.Contract(CHAINS[currentNetwork].nftFactoryImplContract721Abi, selectedMintCollection)
        try {
            const mintMethod = ticketBatch.value > 1 ?
                mintContract.methods.mintWithURIBatch(addresses, tokenURIs)
                :
                mintContract.methods.mintWithURI(walletAddress, jsonIpfsLink)
            const encodedABI = mintMethod.encodeABI()
            console.log(`sending transaction to ${selectedMintCollection}`)
            const tx = await signer.sendTransaction({
                from: walletAddress,
                to: selectedMintCollection,
                data: encodedABI,
            })
            dispatch(setNftTxSigned(true))

            try {
                const receipt = await tx.wait()
                if (receipt && receipt.status === 1) {
                    console.log(receipt)
                    for (let log of receipt.logs) {
                        for (let item of CHAINS[currentNetwork].nftFactoryImplContract721Abi) {
                            if (item.type !== 'event') {
                                continue
                            }
                            if (item.name !== 'Transfer') {
                                continue
                            }
                            const signature = item.name + '(' + item.inputs?.map((input) => input.type).join(',') + ')'
                            const hash = web3?.utils.sha3(signature)
                            if (hash === log.topics[0]) {
                                mintedTokenIds.push(Number(log.topics[3]))
                                break
                            }
                        }
                    }
                    dispatch(setNftTxId(receipt.transactionHash))
                }
            } catch (error: any) {
                console.log(error.receipt)
                showError(i18next.t('error.mintError'), i18next.t('error.mintErrorText'))
                return false
            }
        } catch (e: any) {
            const error = e.message || e.toString()
            console.log(e)
            showError(i18next.t('error.mintError'), error)
            return false
        }
        if (mintedTokenIds.length > 0) {
            dispatch(setMintedTokenId(mintedTokenIds))
            let tickets: TicketRequest[] = []
            for (let id of mintedTokenIds) {
                tickets.push({
                    chain: Number(currentNetwork),
                    contract: selectedMintCollection,
                    tokenId: id,
                    assetType: _AssetType.ERC721,
                    levelId: ticketLevelId,
                })
            }
            if (showcaseType === 'classic') {
                dispatch(sendRequestWithAuth(sendTicketsToWhitelist(tickets)))
            } else {
                dispatch(sendRequestWithAuth(sendTicketsToWhitelist([{
                    chain: Number(currentNetwork),
                    contract: selectedMintCollection,
                    tokenId: -1,
                    assetType: _AssetType.ERC721,
                    levelId: ticketLevelId,
                }])))
            }
        }
        return true
    }
)
export const requestMintCollections = createAsyncThunk(
    'mint/requestMintCollections',
    async (_, {getState}): Promise<ICollection[] | null> => {
        const state = getState() as RootState
        const {currentNetwork, walletAddress, web3} = state.app

        if (!currentNetwork || !walletAddress || !web3) {
            return null
        }

        try {
            const contract = new web3.eth.Contract(CHAINS[currentNetwork].nftFactoryContract721Abi, CHAINS[currentNetwork].nftFactoryContract721)
            const result = await contract.methods.getUsersCollections(walletAddress).call()
            let collections: ICollection[] = []
            for (let item of result) {
                if (Number(item.assetType) !== _AssetType.ERC721) {
                    continue
                }

                const cntr = new web3.eth.Contract(CHAINS[currentNetwork].nftFactoryImplContract721Abi, item.contractAddress)
                const name = await cntr.methods.name().call()
                collections.push({
                    assetType: Number(item.assetType),
                    contractAddress: item.contractAddress.toLowerCase(),
                    name,
                })
            }
            return collections
        } catch (e) {
            console.log(e)
        }
        return []
    }
)
export const sendTicketsToWhitelist = createAsyncThunk(
    'mint/sendTicketsToWhitelist',
    async (tickets: TicketRequest[], {getState, dispatch}): Promise<void> => {
        const state = getState() as RootState
        const {jwt} = state.auth
        const {selectedEventId} = state.events
        const {ticketLevelId} = state.tickets

        let response: SliceResponse = {}
        if (!jwt || !selectedEventId || ticketLevelId === null) {
            response.error = {text: i18next.t('error.jwtEventOrLevelNotFound')}
        } else {
            try {
                const config: any = {headers: {'authorization': `Bearer ${jwt}`}}
                const result = await axios.post(`${API_URL}tickets/${selectedEventId}/whitelist`, {tickets}, config)
                response.status = result.status
                response.data = result.data
            } catch (e: any) {
                response.defaultData = null
                if (e.response) {
                    response.status = e.response.status
                    response.error = {text: e.response.data.error}
                } else {
                    response.error = {text: e.message}
                }
            }
        }
        response.setData = (value) => {
            dispatch(setWhiteListedTickets(value))
        }
        dispatch(checkResponse(response))
    }
)

export const mintSlice = createSlice({
    name: 'mint',
    initialState,
    reducers: {
        resetMintState: (state) => {
            state.nftImageUrl = null
            state.nftJsonUrl = null
            state.nftTxId = null
            state.nftTxSigned = false
            state.mintedTokenId = null
            state.whitelistedTickets = null
        },
        resetState: (state) => {
            let key: keyof MintState
            for (key in initialState) {
                Reflect.set(state, key, initialState[key])
            }
        },
        setCollections: (state, action: PayloadAction<ICollection[] | null>) => {
            state.collections = action.payload
        },
        setMintContract: (state, action: PayloadAction<Contract | null>) => {
            state.mintContract = action.payload
        },
        setMintedTokenId: (state, action: PayloadAction<number[] | null>) => {
            state.mintedTokenId = action.payload
        },
        setNftImageUrl: (state, action: PayloadAction<string | null>) => {
            state.nftImageUrl = action.payload
        },
        setNftJsonUrl: (state, action: PayloadAction<string | null>) => {
            state.nftJsonUrl = action.payload
        },
        setNftTxId: (state, action: PayloadAction<string | null>) => {
            state.nftTxId = action.payload
        },
        setNftTxSigned: (state, action: PayloadAction<boolean>) => {
            state.nftTxSigned = action.payload
        },
        setTicketBatch: (state, action: PayloadAction<number | null>) => {
            state.ticketBatch.value = action.payload
        },
        setTicketBatchError: (state, action: PayloadAction<string | null>) => {
            if (action.payload) {
                state.ticketBatch.error = {status: true, text: action.payload}
            } else {
                state.ticketBatch.error = {status: false, text: ''}
            }
        },
        setTicketDescription: (state, action: PayloadAction<string>) => {
            state.ticketDescription.value = action.payload
        },
        setTicketDescriptionError: (state, action: PayloadAction<string | null>) => {
            if (action.payload) {
                state.ticketDescription.error = {status: true, text: action.payload}
            } else {
                state.ticketDescription.error = {status: false, text: ''}
            }
        },
        setTicketName: (state, action: PayloadAction<string>) => {
            state.ticketName.value = action.payload
        },
        setTicketNameError: (state, action: PayloadAction<string | null>) => {
            if (action.payload) {
                state.ticketName.error = {status: true, text: action.payload}
            } else {
                state.ticketName.error = {status: false, text: ''}
            }
        },
        setWhiteListedTickets: (state, action: PayloadAction<IWhiteListedTickets | null>) => {
            state.whitelistedTickets = action.payload
        },
    },
    extraReducers: (builder) => {
        builder.addCase(requestMintCollections.fulfilled, (state, action: PayloadAction<ICollection[] | null>) => {
            state.collections = action.payload
        })
    },
})

export const getCollections = (state: RootState) => state.mint.collections
export const getMintedTokenId = (state: RootState) => state.mint.mintedTokenId
export const getNftImageUrl = (state: RootState) => state.mint.nftImageUrl
export const getNftJsonUrl = (state: RootState) => state.mint.nftJsonUrl
export const getNftTxId = (state: RootState) => state.mint.nftTxId
export const getNftTxSigned = (state: RootState) => state.mint.nftTxSigned
/*
export const getSelectedCollectionName = (state: RootState) => {
    let name = ''
    if (state.mint.selectedCollection) {
        for (let item of state.mint.collections || []) {
            if (item.contractAddress === state.mint.selectedCollection) {
                name = item.name
                break
            }
        }
    }
    return name
}
*/
export const getTicketBatch = (state: RootState) => state.mint.ticketBatch
export const getTicketDescription = (state: RootState) => state.mint.ticketDescription
export const getTicketName = (state: RootState) => state.mint.ticketName
export const getWhitelistedTickets = (state: RootState) => state.mint.whitelistedTickets

export const {
    resetMintState,
    resetState,
    setCollections,
    setMintContract,
    setMintedTokenId,
    setNftImageUrl,
    setNftJsonUrl,
    setNftTxId,
    setNftTxSigned,
    setTicketBatch,
    setTicketBatchError,
    setTicketDescription,
    setTicketDescriptionError,
    setTicketName,
    setTicketNameError,
    setWhiteListedTickets,
} = mintSlice.actions

export default mintSlice.reducer
