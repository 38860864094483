import React, {useContext, useEffect, useMemo, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {useSearchParams} from 'react-router-dom'
import {useTranslation} from 'react-i18next'
import {AppDispatch} from '../../store/store'
import {getEvents, requestEventsWithFilter, setEvents} from '../../store/eventsSlice'
import {AlertElement, DropdownSelector, IDropdownItem} from '../elements'
import {EVENT_TYPE} from '../../utils/constants'
import {getWalletAddress} from '../../store/appSlice'
import {DBEventWithOrganizer} from '../../store/types'
import {LoadingIcon} from '../icons'
import {ThemeContext} from '../../context'
import {EventCardWithLink} from '../events'
import {getEventTypeList, getMonthList} from '../../utils/functions'

const Events = () => {
    const {t, i18n} = useTranslation()
    const {showcaseType} = useContext(ThemeContext)
    const [searchParams, setSearchParams] = useSearchParams('')
    let month = NaN
    let year = NaN
    if (searchParams.has('month') && searchParams.get('month') !== '') {
        month = Number(searchParams.get('month'))
    }
    if (searchParams.has('year') && searchParams.get('year') !== '') {
        year = Number(searchParams.get('year'))
    }
    const currentYear = (new Date()).getFullYear()
    const [selectedMonth, setSelectedMonth] = useState(isNaN(month) ? (new Date()).getUTCMonth() : month)
    const [selectedYear, setSelectedYear] = useState(isNaN(year) ? currentYear : year)
    const [eventType, setEventType] = useState(EVENT_TYPE.All)
    let yearsArray: IDropdownItem[] = []
    for (let i = 2022; i < currentYear + 3; i++) {
        yearsArray.push({id: i, name: i.toString()})
    }
    const events = useSelector(getEvents)
    const walletAddress = useSelector(getWalletAddress)

    const dispatch = useDispatch<AppDispatch>()

    useEffect(() => {
        if (events) {
            dispatch(setEvents(null))
        }

        searchParams.set('month', selectedMonth.toString())
        searchParams.set('year', selectedYear.toString())
        setSearchParams(searchParams)
        dispatch(requestEventsWithFilter({month: selectedMonth, year: selectedYear}))
    }, [selectedMonth, selectedYear])
    useEffect(() => {
        switch (showcaseType) {
            case 'classic':
                setEventType(EVENT_TYPE.Classic)
                break
            case 'smart':
                setEventType(EVENT_TYPE.Smart)
                break
            default:
                setEventType(EVENT_TYPE.All)
        }
    }, [showcaseType])

    const eventsList = useMemo(() => {
        if (events === null) {
            return <LoadingIcon/>
        }

        let filtered: DBEventWithOrganizer[] = []
        for (let event of events || []) {
            if (event.organizer === walletAddress &&
                (event.showcaseType === EVENT_TYPE[eventType] || eventType === EVENT_TYPE.All)
            ) {
                filtered.push(event)
            }
        }
        if (filtered.length === 0) {
            return <AlertElement centered>{t('status.noEvents')}</AlertElement>
        }

        return filtered.map((item) => (<EventCardWithLink event={item} link={`/${showcaseType}/stats/events/${item.id}`} key={item.id}/>))
    }, [events, eventType, walletAddress, i18n.language])

    if (!showcaseType) {
        return null
    }

    return <div className="page-content">
        <div className="container">
            <div className="text-primary mb-1">{t(`menu.${showcaseType}`)}</div>
            <h1>{showcaseType ? t(`header.${showcaseType}Statistic`) : ''}</h1>
            <div className="mb-5">{t('statistic.pageDescription')}</div>
            <div className="row gx-3 mb-5">
                <div className="col-auto mb-3">
                    <DropdownSelector
                        currentItem={selectedYear}
                        list={yearsArray}
                        setItem={setSelectedYear}
                        button
                    />
                </div>
                <div className="col-auto mb-3">
                    <DropdownSelector
                        currentItem={selectedMonth}
                        list={getMonthList()}
                        setItem={setSelectedMonth}
                        button
                    />
                </div>
                <div className="col-auto mb-3">
                    <DropdownSelector
                        currentItem={eventType}
                        list={getEventTypeList()}
                        setItem={setEventType}
                        button
                    />
                </div>
            </div>
            <div className="row">{eventsList}</div>
        </div>
    </div>
}

export default Events
