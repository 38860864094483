import React, {useContext, useEffect} from 'react'
import {Outlet} from 'react-router-dom'
import {ThemeContext} from '../../context'

const LayoutSmart = () => {
    const {changeDesignMode, changeShowcaseType} = useContext(ThemeContext)

    useEffect(() => {
        changeShowcaseType('smart')
        changeDesignMode('smart-mode')
        return () => {
            changeShowcaseType(null)
            changeDesignMode(null)
        }
    }, [])

    return <Outlet/>
}

export default LayoutSmart
