import React, {useEffect} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {NavLink} from 'react-router-dom'
import {useTranslation} from 'react-i18next'
import {AppDispatch} from '../../store/store'
import {
    getCurrentNetwork,
    getSelectedSbtCollection,
    getWalletAddress,
    setModalCreateSbtCollection, setSelectedSbtCollection
} from '../../store/appSlice'
import {getFromNetworkStorage} from '../../store/storage'
import {getCollections, requestSbtCollections, setCollections as setSbtCollections} from '../../store/sbtSlice'
import {DropdownPlaceholder, DropdownSelector, IDropdownItem} from '../elements'
import {ArrowCurveIcon} from '../icons'

const SelectSbtCollection = () => {
    const {t} = useTranslation()
    const currentNetwork = useSelector(getCurrentNetwork)
    const collections = useSelector(getCollections)
    const selectedCollection = useSelector(getSelectedSbtCollection)
    const walletAddress = useSelector(getWalletAddress)

    const dispatch = useDispatch<AppDispatch>()

    useEffect(() => {
        dispatch(setSelectedSbtCollection(getFromNetworkStorage('selectedSbtCollection', currentNetwork)))
    }, [])
    useEffect(() => {
        if (currentNetwork && walletAddress) {
            dispatch(requestSbtCollections(currentNetwork))
        }
        return () => {
            dispatch(setSbtCollections(null))
        }
    }, [currentNetwork, walletAddress])

    const collectionsList: IDropdownItem[] = collections?.map((item): IDropdownItem => {
        return {id: item.contractAddress, name: item.name}
    }) || []
    const selectCollectionHandler = (address: string) => {
        dispatch(setSelectedSbtCollection(address))
    }
    const createFactoryHandler = () => {
        dispatch(setModalCreateSbtCollection(true))
    }

    return <div className="mb-5">
        <div className="row gx-3">
            <div className="col-8 col-md-4 col-xl-3">
                <div className="mb-3">
                    {collections ?
                        <DropdownSelector
                            list={collectionsList}
                            setItem={selectCollectionHandler}
                            currentItem={selectedCollection}
                            firstItem={{id: 'firstItem', name: t('button.addCollection')}}
                            firstItemAction={createFactoryHandler}
                        />
                        :
                        <DropdownPlaceholder title={`${t('status.loading')}...`}/>
                    }
                </div>
            </div>
            <div className="col-2 col-md-auto">
                {currentNetwork && selectedCollection ?
                    <NavLink
                        to={`/sbt/${Number(currentNetwork)}/${selectedCollection}`}
                        className={'btn btn-link w-100 px-2'}
                    >
                        <ArrowCurveIcon className={'me-md-2'}/>
                        <span className="d-none d-md-inline">{t('button.goToCollection')}</span>
                    </NavLink>
                    :
                    null
                }
            </div>
        </div>
    </div>
}

export default SelectSbtCollection
