import type {AbiItem} from 'web3-utils'
import ArbitrumEventsManagerAbi from './abi/arbitrum-events-manager.json'
import ArbitrumMint721Abi from './abi/arbitrum-mint-721.json'
import ArbitrumMintFactoryAbi from './abi/arbitrum-mint-factory.json'
import ArbitrumMintFactoryImplAbi from './abi/arbitrum-mint-factory-impl.json'
import ArbitrumSbtImpl721Abi from './abi/arbitrum-sbt-impl-721.json'
import ArbitrumShowcaseV2Abi from './abi/arbitrum-showcase-v2.json'
import ArbitrumWrapperBatchAbi from './abi/arbitrum-wrapper-batch.json'
import ArbitrumWrapperBatchPublicAbi from './abi/arbitrum-wrapper-batch-public.json'
import BinanceEventsManagerAbi from './abi/binance-events-manager.json'
import BinanceLaunchpadAbi from './abi/binance-launchpad.json'
import BinanceMint721Abi from './abi/binance-mint-721.json'
import BinanceMintFactoryAbi from './abi/binance-mint-factory.json'
import BinanceMintFactoryImplAbi from './abi/binance-mint-factory-impl.json'
import BinanceSbtImpl721Abi from './abi/binance-sbt-impl-721.json'
import BinanceShowcaseV2Abi from './abi/binance-showcase-v2.json'
import BinanceWrapperBatchAbi from './abi/binance-wrapper-batch.json'
import BinanceWrapperBatchPublicAbi from './abi/binance-wrapper-batch-public.json'
import BlastEventsManagerAbi from './abi/blast-events-manager.json'
import BlastMint721Abi from './abi/blast-mint-721.json'
import BlastMintFactoryAbi from './abi/blast-mint-factory.json'
import BlastMintFactoryImplAbi from './abi/blast-mint-factory-impl.json'
import BlastSbtImpl721Abi from './abi/blast-sbt-impl-721.json'
import BlastShowcaseV2Abi from './abi/blast-showcase-v2.json'
import BlastWrapperBatchAbi from './abi/blast-events-wrapper.json'
import BlastWrapperBatchPublicAbi from './abi/blast-wrapper-batch-public.json'
import EthereumEventsManagerAbi from './abi/ethereum-events-manager.json'
import EthereumMint721Abi from './abi/ethereum-mint-721.json'
import EthereumMintFactoryAbi from './abi/ethereum-mint-factory.json'
import EthereumMintFactoryImplAbi from './abi/ethereum-mint-factory-impl.json'
import EthereumSbtImpl721Abi from './abi/ethereum-sbt-impl-721.json'
import EthereumShowcaseV2Abi from './abi/ethereum-showcase-v2.json'
import EthereumWrapperBatchAbi from './abi/ethereum-wrapper-batch.json'
import EthereumWrapperBatchPublicAbi from './abi/ethereum-wrapper-batch-public.json'
import MaticEventsManagerAbi from './abi/matic-events-manager.json'
import MaticLaunchpadAbi from './abi/matic-launchpad.json'
import MaticMint721Abi from './abi/matic-mint-721.json'
import MaticMintFactoryAbi from './abi/matic-mint-factory.json'
import MaticMintFactoryImplAbi from './abi/matic-mint-factory-impl.json'
import MaticSbtImpl721Abi from './abi/matic-sbt-impl-721.json'
import MaticShowcaseV2Abi from './abi/matic-showcase-v2.json'
import MaticWrapperBatchAbi from './abi/matic-wrapper-batch.json'
import MaticWrapperBatchPublicAbi from './abi/matic-wrapper-batch-public.json'
import SepoliaEventsManagerAbi from './abi/sepolia-events-manager.json'
import SepoliaLaunchpadAbi from './abi/sepolia-launchpad.json'
import SepoliaLazyMintingAbi from './abi/sepolia-lazy-minting.json'
import SepoliaMint721Abi from './abi/sepolia-mint-721.json'
import SepoliaMintFactoryAbi from './abi/sepolia-mint-factory.json'
import SepoliaMintFactoryImplAbi from './abi/sepolia-mint-factory-impl.json'
import SepoliaSbtImpl721Abi from './abi/sepolia-sbt-impl-721.json'
import SepoliaShowcaseV2Abi from './abi/sepolia-showcase-v2.json'
import SepoliaWrapperBatchAbi from './abi/sepolia-wrapper-batch.json'
import SepoliaWrapperBatchPublicAbi from './abi/sepolia-wrapper-batch-public.json'
import {IDropdownItem} from '../components/elements'
import {TShowcaseType} from '../store/types'

import arbitrumIcon from '../static/img/chains-color/arbitrum.svg'
import blastIcon from '../static/img/chains-color/blast.svg'
import bscIcon from '../static/img/chains-color/bnb.svg'
import ethereumIcon from '../static/img/chains-color/ethereum.svg'
import polygonIcon from '../static/img/chains-color/polygon.svg'
import sepoliaIcon from '../static/img/chains-color/sepolia.svg'

export interface ChainType {
    blockExplorer: string
    blockExplorerNftLink: (contract: string, tokenId: string) => string
    eipPrefix: string
    eventsManagerContract: string   //EventManager
    eventsManagerContractAbi: AbiItem[]
    icon: string
    iconSvg: string
    label: string
    nftFactoryContract721: string
    nftFactoryContract721Abi: AbiItem[]
    nftFactoryImplContract721: string
    nftFactoryImplContract721Abi: AbiItem[]
    nftMinterContract721: string
    nftMinterContract721Abi: AbiItem[]
    rpcUrl: string
    sbtImpl721Contract: string  //UsersSBTCollection721BehindProxy
    sbtImpl721ContractAbi: AbiItem[]
    showcaseV2Contract: string
    showcaseV2ContractAbi: AbiItem[]
    showcaseV2PriceModel: Record<TShowcaseType, string>
    testnet: boolean
    tld: string
    token: string
    tokenPrecision: number
    wnftPublic721: string   //EnvelopwNFT721
    wrapperBatchContract: string   //WrapperUsersV1Batch
    wrapperBatchContractAbi: AbiItem[]
    wrapperBatchPublicContract: string   //BatchWorkerV2
    wrapperBatchPublicContractAbi: AbiItem[]
    /*Deprecated contracts*/
    defaultPriceModel: string
    launchpadContract: string
    launchpadContractAbi: AbiItem[]
    lazyMintingContract: string
    lazyMintingContractAbi: AbiItem[]
    lazyMintingPriceModel: string
}

const INFURA_KEY = process.env.REACT_APP_INFURA_KEY?.trim() || ''
export const Wallets: { [key: string]: string } = {
    Metamask: 'Metamask',
    WalletConnect: 'WalletConnect',
}
export const CHAINS: { [key: string]: ChainType } = {
    '0x1': {
        eipPrefix: 'ERC',
        token: 'ETH',
        tokenPrecision: 18,
        label: 'Ethereum',
        testnet: false,
        rpcUrl: `https://mainnet.infura.io/v3/${INFURA_KEY}`,
        icon: ethereumIcon,
        iconSvg: 'ethereum.svg',
        blockExplorer: 'https://etherscan.io',
        blockExplorerNftLink: (contract, tokenId) => (`https://etherscan.io/nft/${contract}/${tokenId}`),
        eventsManagerContract: '0xc5f5B44c983B6345F022a7FeEa94608D5eE9Ee03'.toLowerCase(),
        eventsManagerContractAbi: EthereumEventsManagerAbi as AbiItem[],
        nftFactoryContract721: '0x4913Ba8f120FE99CA88A2dCb8aD546C56c846fdd'.toLowerCase(),
        nftFactoryContract721Abi: EthereumMintFactoryAbi as AbiItem[],
        nftFactoryImplContract721: '0x4C7E818930B631DAef81878b9b036E93CDA35d30'.toLowerCase(),
        nftFactoryImplContract721Abi: EthereumMintFactoryImplAbi as AbiItem[],
        nftMinterContract721: '0xbFb8A10EA886dE9269Cc890fD22785330b323B2b'.toLowerCase(),   //must be synced with backend
        nftMinterContract721Abi: EthereumMint721Abi as AbiItem[],
        sbtImpl721Contract: '0x9C201Eee5248aBe279c82cf8f701c7c777316489'.toLowerCase(),
        sbtImpl721ContractAbi: EthereumSbtImpl721Abi as AbiItem[],
        showcaseV2Contract: '0x1a6723d1A40E5B6549CBFc6AD54b6c16Cdc55181'.toLowerCase(),
        showcaseV2ContractAbi: EthereumShowcaseV2Abi as AbiItem[],
        showcaseV2PriceModel: {
            classic: '0xa2598c4d07d3230412e319b19EEcD40Caf49a709'.toLowerCase(),   //must be synced with backend
            smart: '0x8ddEccB7cd07A750Ff679B8fA635e582c3c8c979'.toLowerCase(),   //must be synced with backend
        },
        tld: '',
        wnftPublic721: '0x05e9B409707Ad82Ed4657fF8B7174d2340e8ef23'.toLowerCase(),
        wrapperBatchContract: '0x40a0F18F7efaD086127797dE7F2558633468FA50'.toLowerCase(),
        wrapperBatchContractAbi: EthereumWrapperBatchAbi as AbiItem[],
        wrapperBatchPublicContract: '0xc92d35e9042076465e244545802c127e22eFE8D3'.toLowerCase(),
        wrapperBatchPublicContractAbi: EthereumWrapperBatchPublicAbi as AbiItem[],
        /*Deprecated contracts*/
        defaultPriceModel: '',
        launchpadContract: '',
        launchpadContractAbi: [],
        lazyMintingContract: '',
        lazyMintingContractAbi: [],
        lazyMintingPriceModel: '',
    },
    '0x38': {
        eipPrefix: 'BEP',
        token: 'BNB',
        tokenPrecision: 18,
        label: 'BSC/BNB chain',
        testnet: false,
        rpcUrl: 'https://bsc-dataseed.binance.org/',
        icon: bscIcon,
        iconSvg: 'bnb.svg',
        blockExplorer: 'https://bscscan.com',
        blockExplorerNftLink: (contract, tokenId) => (`https://bscscan.com/nft/${contract}/${tokenId}`),
        eventsManagerContract: '0x1A8DB5DB83B46e3fC9298f46f445021f2bA58cE4'.toLowerCase(),
        eventsManagerContractAbi: BinanceEventsManagerAbi as AbiItem[],
        nftFactoryContract721: '0xFab30f90510F5a113bb4fd7aDEEB9Ad5d48EB6Ee'.toLowerCase(),
        nftFactoryContract721Abi: BinanceMintFactoryAbi as AbiItem[],
        nftFactoryImplContract721: '0xBD758529fD165A1D6340522b14bAc97AB5b5197C'.toLowerCase(),
        nftFactoryImplContract721Abi: BinanceMintFactoryImplAbi as AbiItem[],
        nftMinterContract721: '0x0C40D8C0BA6A8B97cF3a43Bf34C61e2b3299984b'.toLowerCase(),   //must be synced with backend
        nftMinterContract721Abi: BinanceMint721Abi as AbiItem[],
        sbtImpl721Contract: '0x317F5cFf44fcF5d594BBC2F0FdBcD158b7Ca4C40'.toLowerCase(),
        sbtImpl721ContractAbi: BinanceSbtImpl721Abi as AbiItem[],
        showcaseV2Contract: '0x70D3D9AB080A20B2C94945F07d7d63f1F2b845c5'.toLowerCase(),
        showcaseV2ContractAbi: BinanceShowcaseV2Abi as AbiItem[],
        showcaseV2PriceModel: {
            classic: '0x04869daA878e1a20dfd157C7AC188838ab4E73eC'.toLowerCase(),   //must be synced with backend
            smart: '0x767b4522DC176a43A2e2f2eA0f3cD1411d87D6FC'.toLowerCase(),   //must be synced with backend
        },
        tld: 'bnb',
        wnftPublic721: '0x391a5753cAa32f1a636DE4CAed2d999D2dE16B7b'.toLowerCase(),
        wrapperBatchContract: '0xa76689cbC9164eD06494b166B6B44b6321E92f3A'.toLowerCase(),
        wrapperBatchContractAbi: BinanceWrapperBatchAbi as AbiItem[],
        wrapperBatchPublicContract: '0xf1D1B3152573881135b50816d0e0e874E01F0464'.toLowerCase(),
        wrapperBatchPublicContractAbi: BinanceWrapperBatchPublicAbi as AbiItem[],
        /*Deprecated contracts*/
        defaultPriceModel: '0x93142C2BCB7cD320de4a6D52D070FfeB3f6e435B'.toLowerCase(),
        launchpadContract: '0x93142C2BCB7cD320de4a6D52D070FfeB3f6e435B'.toLowerCase(),
        launchpadContractAbi: BinanceLaunchpadAbi as AbiItem[],
        lazyMintingContract: '',
        lazyMintingContractAbi: [],
        lazyMintingPriceModel: '',
    },
    '0x89': {
        eipPrefix: 'ERC',
        token: 'MATIC',
        tokenPrecision: 18,
        label: 'Polygon',
        testnet: false,
        rpcUrl: 'https://polygon-rpc.com/',
        icon: polygonIcon,
        iconSvg: 'polygon.svg',
        blockExplorer: 'https://polygonscan.com',
        blockExplorerNftLink: (contract, tokenId) => (`https://polygonscan.com/nft/${contract}/${tokenId}`),
        eventsManagerContract: '0x0C40D8C0BA6A8B97cF3a43Bf34C61e2b3299984b'.toLowerCase(),
        eventsManagerContractAbi: MaticEventsManagerAbi as AbiItem[],
        nftFactoryContract721: '0xb2d625d57588583019C554C3A71f123b548F72e2'.toLowerCase(),
        nftFactoryContract721Abi: MaticMintFactoryAbi as AbiItem[],
        nftFactoryImplContract721: '0xcB7EAa0DbBACFe16FF9bA2676B07D3783D07E568'.toLowerCase(),
        nftFactoryImplContract721Abi: MaticMintFactoryImplAbi as AbiItem[],
        nftMinterContract721: '0x721f94A6669ADF57eD100Fe1a914B94aCd0aDC23'.toLowerCase(),   //must be synced with backend
        nftMinterContract721Abi: MaticMint721Abi as AbiItem[],
        sbtImpl721Contract: '0x79768b465412979D04Ede5bBe974e1F5cc43Bb14'.toLowerCase(),
        sbtImpl721ContractAbi: MaticSbtImpl721Abi as AbiItem[],
        showcaseV2Contract: '0x3930092B1e34A97c1ea7e0A67D9Be536BEcbe3A3'.toLowerCase(),
        showcaseV2ContractAbi: MaticShowcaseV2Abi as AbiItem[],
        showcaseV2PriceModel: {
            classic: '0x2A741d74f5858D5e8993494b2eF8e80A4aD7C42f'.toLowerCase(),   //must be synced with backend
            smart: '0x5f2A84617E8529A470e9FaC73193Acf0F2960853'.toLowerCase(),   //must be synced with backend
        },
        tld: '',
        wnftPublic721: '0x1Ed8192facc23B771B21934346745ecBCFbEE0e6'.toLowerCase(),
        wrapperBatchContract: '0x9992325EFC8c62C24DedF61Fb60Cf6a632Eea36c'.toLowerCase(),
        wrapperBatchContractAbi: MaticWrapperBatchAbi as AbiItem[],
        wrapperBatchPublicContract: '0x95f2541Ee5F0825AEfcE1aBA0aA0c7B61093cdfB'.toLowerCase(),
        wrapperBatchPublicContractAbi: MaticWrapperBatchPublicAbi as AbiItem[],
        /*Deprecated contracts*/
        defaultPriceModel: '0x7758E593C7901e031F1eEF33C3F41D0c321dfA42'.toLowerCase(),
        launchpadContract: '0x27674BE4929Ac27b322542a497c7f9dB28B17bf6'.toLowerCase(),
        launchpadContractAbi: MaticLaunchpadAbi as AbiItem[],
        lazyMintingContract: '',
        lazyMintingContractAbi: [],
        lazyMintingPriceModel: '',
    },
    '0xa4b1': {
        eipPrefix: 'ERC',
        token: 'ETH',
        tokenPrecision: 18,
        label: 'Arbitrum',
        testnet: false,
        rpcUrl: 'https://arb1.arbitrum.io/rpc',
        icon: arbitrumIcon,
        iconSvg: 'arbitrum-one.svg',
        blockExplorer: 'https://arbiscan.io',
        blockExplorerNftLink: (contract, tokenId) => (`https://arbiscan.io/token/${contract}?a=${tokenId}`),
        eventsManagerContract: '0x33ec38185f213D6e75ba26F711ba309b8BcD211a'.toLowerCase(),
        eventsManagerContractAbi: ArbitrumEventsManagerAbi as AbiItem[],
        nftFactoryContract721: '0x6A148bFA97ecF9714CA049bfBFd7Ccb7cdb1C7fF'.toLowerCase(),
        nftFactoryContract721Abi: ArbitrumMintFactoryAbi as AbiItem[],
        nftFactoryImplContract721: '0x2E2F00Dfac24C4cCB9c7cCACacFc066bAa2938f5'.toLowerCase(),
        nftFactoryImplContract721Abi: ArbitrumMintFactoryImplAbi as AbiItem[],
        nftMinterContract721: '0x3F4deAC77d3aEb6aF2B827Cf8B0e772239F2D3e9'.toLowerCase(),   //must be synced with backend
        nftMinterContract721Abi: ArbitrumMint721Abi as AbiItem[],
        sbtImpl721Contract: '0xa3dE20737cd36070352e3e84cFa9EeBc3A4eeb4B'.toLowerCase(),
        sbtImpl721ContractAbi: ArbitrumSbtImpl721Abi as AbiItem[],
        showcaseV2Contract: '0x5FC887c4E1E83ed8902Be78611CEA944757E61B2'.toLowerCase(),
        showcaseV2ContractAbi: ArbitrumShowcaseV2Abi as AbiItem[],
        showcaseV2PriceModel: {
            classic: '0xdEB7534d505724Ce45C0912f006A70ef101fdC00'.toLowerCase(),   //must be synced with backend
            smart: '0x33BA36716b5a7CaE16C309a57aC66b02256bCA2d'.toLowerCase(),   //must be synced with backend
        },
        tld: 'arb',
        wnftPublic721: '0x50B80032A0727DC9831b50d378B0be0EB325797E'.toLowerCase(),
        wrapperBatchContract: '0x6AFBDdFae69D6fA7a8EE615329956E9d681E8241'.toLowerCase(),
        wrapperBatchContractAbi: ArbitrumWrapperBatchAbi as AbiItem[],
        wrapperBatchPublicContract: '0xfc868C5e9B53270f1c51B5c874858d4392f32291'.toLowerCase(),
        wrapperBatchPublicContractAbi: ArbitrumWrapperBatchPublicAbi as AbiItem[],
        /*Deprecated contracts*/
        defaultPriceModel: '',
        launchpadContract: '',
        launchpadContractAbi: [],
        lazyMintingContract: '',
        lazyMintingContractAbi: [],
        lazyMintingPriceModel: '',
    },
    '0x13e31': {
        eipPrefix: 'ERC',
        token: 'ETH',
        tokenPrecision: 18,
        label: 'Blast',
        testnet: false,
        rpcUrl: 'https://rpc.blast.io',
        icon: blastIcon,
        iconSvg: 'blast.svg',
        blockExplorer: 'https://blastscan.io',
        blockExplorerNftLink: (contract, tokenId) => (`https://blastscan.io/token/${contract}?a=${tokenId}`),
        eventsManagerContract: '0xBB990bAFA2BB5b733d2E4A2dB7F18760fe440980'.toLowerCase(),
        eventsManagerContractAbi: BlastEventsManagerAbi as AbiItem[],
        nftFactoryContract721: '0x446EC4c1793B664Bb4f42b956DdD147362Ef4AB4'.toLowerCase(),
        nftFactoryContract721Abi: BlastMintFactoryAbi as AbiItem[],
        nftFactoryImplContract721: '0xA5F11D60d96370878140Fba8783d705C41BDe3BE'.toLowerCase(),
        nftFactoryImplContract721Abi: BlastMintFactoryImplAbi as AbiItem[],
        nftMinterContract721: '0x6426Bc3F86E554f44bE7798c8D1f3482Fb7BB68C'.toLowerCase(),   //must be synced with backend
        nftMinterContract721Abi: BlastMint721Abi as AbiItem[],
        sbtImpl721Contract: '0x2C72097760B3f0E781C9499dD94486E46DFD664C'.toLowerCase(),
        sbtImpl721ContractAbi: BlastSbtImpl721Abi as AbiItem[],
        showcaseV2Contract: '0x60ADcF7ef3649761CC4c8f28472D508C83288116'.toLowerCase(),
        showcaseV2ContractAbi: BlastShowcaseV2Abi as AbiItem[],
        showcaseV2PriceModel: {
            classic: '0xEAC43916C228C5b3d55aD25Db58c99cBd8ff09DE'.toLowerCase(),   //must be synced with backend
            smart: '0x282B973817Dc7d149b05474b848610Fc70Bf4CF2'.toLowerCase(),   //must be synced with backend
        },
        tld: '',
        wnftPublic721: '0xDa4A800f6eB5e84B1A32AcbAf8918d76bbE2Fc00'.toLowerCase(),
        wrapperBatchContract: '0xaDCb269E335F96A75d84032aAbc7370B2C199EDc'.toLowerCase(),
        wrapperBatchContractAbi: BlastWrapperBatchAbi as AbiItem[],
        wrapperBatchPublicContract: '0x33BA36716b5a7CaE16C309a57aC66b02256bCA2d'.toLowerCase(),
        wrapperBatchPublicContractAbi: BlastWrapperBatchPublicAbi as AbiItem[],
        /*Deprecated contracts*/
        defaultPriceModel: '',
        launchpadContract: '',
        launchpadContractAbi: [],
        lazyMintingContract: '',
        lazyMintingContractAbi: [],
        lazyMintingPriceModel: '',
    },
    '0xaa36a7': {
        eipPrefix: 'ERC',
        token: 'ETH',
        tokenPrecision: 18,
        label: 'Sepolia',
        testnet: true,
        rpcUrl: `https://sepolia.infura.io/v3/${INFURA_KEY}`,
        icon: sepoliaIcon,
        iconSvg: 'ethereum.svg',
        blockExplorer: 'https://sepolia.etherscan.io',
        blockExplorerNftLink: (contract, tokenId) => (`https://sepolia.etherscan.io/token/${contract}?a=${tokenId}`),
        eventsManagerContract: '0xfE693E733FAe1E82d7c45661C7562A27Ec3F5C22'.toLowerCase(),
        eventsManagerContractAbi: SepoliaEventsManagerAbi as AbiItem[],
        nftFactoryContract721: '0x883542Ca608Bf9eb18f907e02Fe1a9d2cfC5c20C'.toLowerCase(),
        nftFactoryContract721Abi: SepoliaMintFactoryAbi as AbiItem[],
        nftFactoryImplContract721: '0xF7C3276F70E679ED3cB355a2c992FB53b77796c6'.toLowerCase(),
        nftFactoryImplContract721Abi: SepoliaMintFactoryImplAbi as AbiItem[],
        nftMinterContract721: '0x1bbc7e5B329b4C79505309ABFe0c03455D2490A3'.toLowerCase(),   //must be synced with backend
        nftMinterContract721Abi: SepoliaMint721Abi as AbiItem[],
        sbtImpl721Contract: '0x67197e4920bF7c577f56D6e7A37EbFd31344c832'.toLowerCase(),
        sbtImpl721ContractAbi: SepoliaSbtImpl721Abi as AbiItem[],
        showcaseV2Contract: '0xFdfE0D327be0966CAa18B5c252C582C99f8E728e'.toLowerCase(),
        showcaseV2ContractAbi: SepoliaShowcaseV2Abi as AbiItem[],
        showcaseV2PriceModel: {
            classic: '0x8b70292E0816ECB9583100dc1331DA383eBD376b'.toLowerCase(),   //must be synced with backend
            smart: '0xBF62A0918E8E461b8d0bE8e266448F5ff8aecC63'.toLowerCase(),   //must be synced with backend
        },
        tld: '',
        wnftPublic721: '0x0C9b826dbA10268F1E64E5Bb212242C6d9BE864a'.toLowerCase(),
        wrapperBatchContract: '0x518d6a37493AF5917DAbECe74F41cc6697EbE831'.toLowerCase(),
        wrapperBatchContractAbi: SepoliaWrapperBatchAbi as AbiItem[],
        wrapperBatchPublicContract: '0x7EDD6E3176b02Eaf229ca362C4beB6CC0bE2E80b'.toLowerCase(),
        wrapperBatchPublicContractAbi: SepoliaWrapperBatchPublicAbi as AbiItem[],
        /*Deprecated contracts*/
        defaultPriceModel: '0xDf5bD98bA74444613FbBc4edcb515Ee75De76925'.toLowerCase(),
        launchpadContract: '0x714a99BeA9F0CCb3c42bc4db944E09f67F889E7D'.toLowerCase(),
        launchpadContractAbi: SepoliaLaunchpadAbi as AbiItem[],
        lazyMintingContract: '0xf9DC2671C99D8D7FB8F66b31d25ea0cbac6C4790'.toLowerCase(),
        lazyMintingContractAbi: SepoliaLazyMintingAbi as AbiItem[],
        lazyMintingPriceModel: '0x1b7f68Ab7F6eAF49e64880B7B64c1F10dC405a78'.toLowerCase(),
    },
}
export const ERC20_TOKENS: { [key: string]: string[] } = {
    //Ethereum
    '0x1': [
        '0x6b175474e89094c44da98b954eedeac495271d0f'.toLowerCase(), //DAI
        '0xdAC17F958D2ee523a2206206994597C13D831ec7'.toLowerCase(), //USDT
        '0xA0b86991c6218b36c1d19D4a2e9Eb0cE3606eB48'.toLowerCase(), //USDC
    ],
    //Binance
    '0x38': [
        '0x8ac76a51cc950d9822d68b83fe1ad97b32cd580d'.toLowerCase(), //USDC
        '0x1af3f329e8be154074d8769d1ffa4ee058b1dbc3'.toLowerCase(), //DAI
        '0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56'.toLowerCase(), //BUSD
        '0x55d398326f99059ff775485246999027b3197955'.toLowerCase(), //USDT
    ],
    //Poligon
    '0x89': [
        '0x8f3Cf7ad23Cd3CaDbD9735AFf958023239c6A063'.toLowerCase(), //DAI
        '0xc2132d05d31c914a87c6611c10748aeb04b58e8f'.toLowerCase(), //USDT
//        '0x2791bca1f2de4661ed88a30c99a7a9449aa84174'.toLowerCase(), //USDC.e
        '0x3c499c542cEF5E3811e1192ce70d8cC03d5c3359'.toLowerCase(), //USDC
    ],
    //Arbitrum
    '0xa4b1': [
        '0xFd086bC7CD5C481DCC9C85ebE478A1C0b69FCbb9'.toLowerCase(), //USDT
        '0xaf88d065e77c8cC2239327C5EDb3A432268e5831'.toLowerCase(), //USDC
        '0xDA10009cBd5D07dd0CeCc66161FC93D7c9000da1'.toLowerCase(), //DAI
    ],
    //Blast
    '0x13e31': [
        '0x4300000000000000000000000000000000000003'.toLowerCase(), //USDB
    ],
    //Sepolia
    '0xaa36a7': [],
}
export const SHOWCASE_TYPES: Record<TShowcaseType, string> = {
    classic: 'Classic showcase',
    smart: 'Smart showcase',
}
export const COPY_HINT_TIME = 2000  //ms
export const EVENT_FACTORY_BASE_URI = ''
export const GIFT_FACTORY_BASE_URI = ''
export const MAX_COLLATERAL_LEN = 25
export const MAX_UNLOCK_TIME = 1000
export const MAX_UPLOAD_SIZE = 10 << 20
export const MINT_FACTORY_BASE_URI = ''
export const NULL_ADDRESS = '0x0000000000000000000000000000000000000000'
export const SBT_FACTORY_BASE_URI = ''
export const SHOWCASE_TOKENS_ON_PAGE = 12

export enum _AssetType {
    wNFTv0 = -1,
    empty = 0,
    native = 1,
    ERC20 = 2,
    ERC721 = 3,
    ERC1155 = 4
}

export enum EVENT_TYPE {
    All,
    Classic,
    Mixed,
    Smart,
    Unknown,
}

export enum MAIN_MENUS {
    certificates,
    classic,
    gifts,
    onchain,
    smart,
}

export enum SUB_MENU {
    classicEvents,
    classicMint,
    classicShowcases,
    classicStat,
    onchainEvents,
    onchainStat,
    smartEvents,
    smartMint,
    smartShowcases,
    smartStat,
}

export const WNFT_RULES = {
    noTransfer: 4,  //0x0100
}

export const VAR_KEYS = {
    EventTicketDescription: 'EventTicketDescription',
}
export const CUSTOM_DROPDOWN_ITEM: IDropdownItem = {id: 'custom', name: 'Custom'}
export const API_URL = process.env.REACT_APP_API_URL?.trim() || '/api/'
export const APP_URL = process.env.REACT_APP_URL?.trim() || ''
export const WALLETCONNECT_ID = process.env.REACT_APP_WALLETCONNECT_ID?.trim() || ''
export const THIRDWEB_CLIENT_ID = process.env.REACT_APP_THIRDWEB_CLIENT_ID?.trim() || ''
export const THIRDWEB_SECRET_KEY = process.env.REACT_APP_THIRDWEB_SECRET_KEY?.trim() || ''
export const ENVIRONMENT = process.env.REACT_APP_ENVIRONMENT?.trim() || 'prod'
export const LOCALIZATION_DEBUG = process.env.REACT_APP_LOCALIZATION_DEBUG?.trim().toLowerCase() === 'true' || false
export const TG_AUTH_BOT = process.env.REACT_APP_TG_AUTH_BOT?.trim() || ''
