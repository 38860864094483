import React, {useEffect, useState} from 'react'
import {shallowEqual, useDispatch, useSelector} from 'react-redux'
import {ethers} from 'ethers'
import {useConnectWallet} from '@web3-onboard/react'
import {useWeb3Onboard} from '@web3-onboard/react/dist/context'
import {getJwt, getSigningNonce, getUser, requestAuth, signUserNonce} from '../../store/authSlice'
import {AppDispatch} from '../../store/store'
import {
    getCurrentNetwork,
    getRequestQueue,
    getSigner,
    getWalletAddress, getWalletQueue, resetRequestQueue,
    setCurrentNetwork,
    setSigner,
    setWalletAddress, setWalletQueue,
    walletChanged
} from '../../store/appSlice'
import {setToStorage} from '../../store/storage'
import {IWalletQueue} from '../../store/types'

const Authorization = () => {
    const [changingNetwork, setChangingNetwork] = useState(false)
    const currentNetwork = useSelector(getCurrentNetwork)
    const jwt = useSelector(getJwt)
    const requestQueue = useSelector(getRequestQueue)
    const signer = useSelector(getSigner)
    const signingNonce = useSelector(getSigningNonce)
    const user = useSelector(getUser, shallowEqual)
    const walletAddress = useSelector(getWalletAddress)
    const walletQueue = useSelector(getWalletQueue)

    const dispatch = useDispatch<AppDispatch>()
    const [{wallet, connecting}, connect] = useConnectWallet()
    const onboard = useWeb3Onboard()

    useEffect(() => {
        if (wallet) {
            setToStorage('connectedWallet', wallet.label)
            if (walletAddress !== wallet.accounts[0].address) {
                dispatch(setWalletAddress(wallet.accounts[0].address))
                if (walletAddress) {
                    dispatch(walletChanged())
                }
            }
            dispatch(setCurrentNetwork(wallet.chains[0].id))
            const ethersProvider = new ethers.providers.Web3Provider(wallet.provider, 'any')
            const signer = ethersProvider.getSigner()
            dispatch(setSigner(signer))
            if (!user) {
                dispatch(requestAuth({account: wallet.accounts[0].address, jwt}))
            }
        }
    }, [wallet])
    useEffect(() => {
        if (user && user.msg && !user.auth && signer && !jwt && !signingNonce) {
            dispatch(signUserNonce())
        }
    }, [user])
    useEffect(() => {
        if (walletAddress && jwt) {
            dispatch(requestAuth({account: walletAddress, jwt}))
        }
    }, [jwt])
    useEffect(() => {
        if (!requestQueue.length) {
            return
        }

        if (!wallet) {
            if (!connecting) {
                connect()
            }
            return
        }

        if (!walletAddress || !user) {
            return
        }

        if (user.auth && jwt) {
            for (let item of requestQueue) {
                dispatch(item)
            }
            dispatch(resetRequestQueue())
        }
    }, [requestQueue, user, signer, jwt])
    useEffect(() => {
        if (changingNetwork) {
            setChangingNetwork(false)
        }
    }, [currentNetwork])
    useEffect(() => {
        if (!walletQueue.length) {
            return
        }

        if (!wallet) {
            if (!connecting) {
                connect()
            }
            return
        }

        if (walletAddress) {
            let newQueue: IWalletQueue[] = []
            let changing = false
            let queueChanged = false
            for (let i in walletQueue) {
                const item = walletQueue[i]
                if (!item.network || item.network === currentNetwork) {
                    dispatch(item.request)
                    queueChanged = true
                } else {
                    if (item.network && !changingNetwork && !changing) {
                        onboard.setChain({chainId: item.network})
                        setChangingNetwork(true)
                        changing = true
                    }
                    newQueue.push(item)
                }
            }
            if (queueChanged) {
                dispatch(setWalletQueue(newQueue))
            }
        }
    }, [currentNetwork, walletAddress, walletQueue])

    return <></>
}

export default Authorization
