import React, {useContext} from 'react'
import {Outlet} from 'react-router-dom'
import {useSelector} from 'react-redux'
import {Footer, Header} from '.'
import {getMainPageClass} from '../../store/appSlice'
import {Redirect, Referral} from '../system'
import ModalManager from '../modals/ModalManager'
import {ThemeContext} from '../../context'
import {HeaderAuthAlert} from '../wallet'

const LayoutDefault = () => {
    const {dark, designMode} = useContext(ThemeContext)
    const mainPageClass = useSelector(getMainPageClass)

    return <div className={`wrapper ${designMode || ''}`} data-theme={dark ? '' : 'light'}>
        <Referral/>
        <Redirect/>
        <HeaderAuthAlert/>
        <Header/>
        <main className={`s-main ${mainPageClass || ''}`}>
            <Outlet/>
            <ModalManager/>
        </main>
        <Footer/>
    </div>
}

export default LayoutDefault
