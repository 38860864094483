import React, {useEffect, useMemo} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {useTranslation} from 'react-i18next'
import {setModalAddProperties} from '../../store/appSlice'
import {ButtonElement} from '../elements'
import {getProperties, setProperties, setPropertiesError} from '../../store/inputSlice'
import {PropertyRow} from './index'

const PropertiesBlock = () => {
    const {t} = useTranslation()
    const properties = useSelector(getProperties)

    const dispatch = useDispatch()

    useEffect(() => {
        return () => {
            dispatch(setProperties([]))
        }
    }, [])

    const deletePropertyHandler = (index: number) => {
        let newState = [...properties.value]
        newState.splice(index, 1)
        dispatch(setProperties(newState))
        if (properties.error.status) {
            let error = false
            for (let item of newState) {
                if (item.trait_type === '' || item.value === '') {
                    error = true
                    break
                }
            }
            if (!error) {
                dispatch(setPropertiesError(null))
            }
        }
    }
    const editPropertiesHandler = () => {
        dispatch(setModalAddProperties(true))
    }

    const propertiesList = useMemo(() => {
        return properties.value.map((item, index) => {
            return <PropertyRow property={item} delHandler={deletePropertyHandler} index={index}/>
        })
    }, [properties.value])

    return <div className="mb-6">
        <div className="mb-3">
            <ButtonElement
                small
                outline
                onClick={editPropertiesHandler}
            >{properties.value.length > 0 ? t('button.editProperties') : t('button.addProperties')}</ButtonElement>
        </div>
        {properties.error.status ?
            <div className="alert-danger mb-3">{properties.error.text}</div>
            :
            null
        }
        {properties.value.length ?
            <div className="row">
                <div className="col-xl-8">
                    <div className="row-table">
                        {propertiesList}
                    </div>
                </div>
            </div>
            :
            null
        }
    </div>
}

export default PropertiesBlock
