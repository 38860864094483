import React from 'react'
import {IconProps} from '../../store/types'

const TwitterIcon = (props: IconProps) => {
    return <svg className={props.className} width="25" height="25" viewBox="0 0 30 30" fill="none"
                xmlns="http://www.w3.org/2000/svg">
        <path fillRule="evenodd" clipRule="evenodd"
              d="M15 30C23.2843 30 30 23.2843 30 15C30 6.71573 23.2843 0 15 0C6.71573 0 0 6.71573 0 15C0 23.2843 6.71573 30 15 30ZM22.9361 7H20.1755L15.6272 12.033L11.6956 7H6L12.8059 15.6133L6.35526 22.75H9.11684L14.0953 17.2438L18.4456 22.75H24L16.9061 13.6718L22.9361 7ZM20.7363 21.1506H19.2073L9.22389 8.51502H10.8647L20.7363 21.1506Z"></path>
    </svg>
}

export default TwitterIcon
