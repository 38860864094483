import React, {useRef, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {ethers} from 'ethers'
import {useConnectWallet} from '@web3-onboard/react'
import {Trans, useTranslation} from 'react-i18next'
import {getCurrentNetwork, setModalAddTicketsToShowcase, setModalError} from '../../store/appSlice'
import {ButtonElement, DropdownSelector, InputElement} from '../elements'
import {getCoins, getCoinsObject, getStandardCoinsList, requestCoin} from '../../store/coinsSlice'
import {ITicketPrice} from '../../store/types'
import {CHAINS, CUSTOM_DROPDOWN_ITEM, ERC20_TOKENS, NULL_ADDRESS} from '../../utils/constants'
import {checkPriceFormat} from '../../utils/functions'
import {AppDispatch} from '../../store/store'
import {AddIcon, CloseIcon} from '../icons'
import {addBatchItemsToDisplayWithSamePrice, getDisplayName, getSelectedTickets} from '../../store/launchpadSlice'

interface propsType {
    show: boolean
}

const getEmptyPrice = (): ITicketPrice => ({
    price: '',
    priceError: '',
    decimals: 18,
    token: NULL_ADDRESS,
    customContract: '',
    customContractError: ''
})

const ModalAddTicketsToShowcase = (props: propsType) => {
    const {t} = useTranslation()
    const modalFade = useRef(null)
    const [prices, setPrices] = useState<ITicketPrice[]>([getEmptyPrice()])
    const setPrice = (index: number, value: ITicketPrice) => {
        setPrices((prevState) => {
            let newState = [...prevState]
            newState[index] = {...value}
            return newState
        })
    }
    const [pricesError, setPricesError] = useState('')
    const coins = useSelector(getCoins)
    const coinsList = useSelector(getStandardCoinsList)
    const coinsObject = useSelector(getCoinsObject)
    const currentNetwork = useSelector(getCurrentNetwork)
    const displayName = useSelector(getDisplayName)
    const selectedTickets = useSelector(getSelectedTickets)

    const dispatch = useDispatch<AppDispatch>()

    const [{wallet}] = useConnectWallet()

    if (currentNetwork) {
        for (let item of coins || []) {
            if (ERC20_TOKENS[currentNetwork].indexOf(item.contract) >= 0) {
                for (let i = 0; i < prices.length; i++) {
                    const price = prices[i]
                    if (((price.token !== CUSTOM_DROPDOWN_ITEM.id && item.contract === price.token) ||
                            (price.token === CUSTOM_DROPDOWN_ITEM.id && item.contract === price.customContract)) &&
                        price.decimals !== item.decimals
                    ) {
                        setPrice(i, {...price, decimals: item.decimals})
                    }
                }
            }
        }
    }

    const closeModal = () => {
        dispatch(setModalAddTicketsToShowcase(false))
    }
    const customContractHandler = (index: number) => (value: string) => {
        if (ethers.utils.isAddress(value) || value === '') {
            setPrice(index, {...prices[index], customContract: value.toLowerCase(), customContractError: ''})
            dispatch(requestCoin({address: value, network: currentNetwork}))
        } else {
            setPrice(index, {
                ...prices[index],
                customContractError: t('error.customContractError', {name: currentNetwork ? CHAINS[currentNetwork].eipPrefix : 'ERC'})
            })
        }
    }
    const onChangePriceHandler = (index: number) => (value: string) => {
        let price = value.replace(',', '.')
        if (price === '' || checkPriceFormat(price, prices[index].decimals)) {
            const pos = price.indexOf('.')
            if (pos > 0) {
                price = price.substring(0, pos + 1 + prices[index].decimals)
            }
            setPrice(index, {...prices[index], price})
        }
    }
    const onChangeTokenHandler = (index: number, id: string) => {
        setPrice(index, {...prices[index], token: id, decimals: coinsObject[id]?.decimals || 18})
    }
    const addHandler = () => {
        let error = false
        if (prices.length === 0) {
            error = true
            setPricesError(t('error.setPrice'))
        } else {
            setPricesError('')
        }
        let newPrices = []
        for (let i = 0; i < prices.length; i++) {
            const item = prices[i]
            const price = Number(item.price)
            if (item.price === '' || isNaN(price) || price < 0) {
                error = true
                item.priceError = t('error.mustBeGreater', {name: t('form.label.price'), value: 0})
            } else {
                item.priceError = ''
            }

            if (item.token === CUSTOM_DROPDOWN_ITEM.id && !ethers.utils.isAddress(item.customContract)) {
                error = true
                item.customContractError = t('error.wrong', {name: t('form.label.address')})
            } else {
                item.customContractError = ''
            }
            newPrices.push(item)
        }
        setPrices(newPrices)

        if (!displayName || error) {
            return
        }
        if (!wallet) {
            dispatch(setModalError({text: t('error.walletNotConnected'), buttons: ['close']}))
            return
        }

        dispatch(addBatchItemsToDisplayWithSamePrice({
            prices: prices.map(item => ({
                amount: ethers.utils.parseUnits(item.price, item.decimals) as ethers.BigNumber,
                payWith: item.token !== CUSTOM_DROPDOWN_ITEM.id ? item.token : item.customContract
            }))
        }))
    }

    if (selectedTickets.length === 0 || !currentNetwork) {
        closeModal()
        return null
    }

    if (!props.show) {
        return null
    }

    return <div
        className={`modal modal-lg fade show`}
        ref={modalFade}
        tabIndex={-1}
        aria-labelledby="Set price for the selected tickets"
        aria-hidden="true"
        style={{display: 'block'}}
        onMouseDown={(e) => {
            if (e.target === modalFade.current) {
                closeModal()
            }
        }}
    >
        <div className="modal-dialog">
            <div className="modal-content">
                <div className="modal-header">
                    <h2 className="modal-title">
                        <Trans i18nKey={'modal.title.setPriceForTickets'} components={[<span className="text-primary"/>]}/>
                    </h2>
                    <button className="btn p-2 btn-with-opacity btn-modal-close" onClick={closeModal}>
                        <CloseIcon/>
                    </button>
                </div>
                <div className="modal-body pt-0">
                    <div className="mb-lg-2">
                        {pricesError !== '' ? <div className={'alert-danger'}>{pricesError}</div> : null}
                        <div className="row gx-3 d-none d-lg-flex">
                            <div className="col-lg-3">
                                <div className="mb-2">
                                    <label className="form-label">{t('form.label.price')}</label>
                                </div>
                            </div>
                            <div className="col-lg-3">
                                <div className="mb-2">
                                    <label className="form-label">{t('form.label.token')}</label>
                                </div>
                            </div>
                        </div>
                        {prices.map((item, index) => {
                            return <div key={index} className="row gx-2 gx-lg-3">
                                <div className="col-6 col-lg-3">
                                    <div className="mb-lg-3">
                                        <label className="form-label d-lg-none">{t('form.label.price')}</label>
                                        <InputElement
                                            errorText={item.priceError}
                                            value={item.price.toString()}
                                            onChange={onChangePriceHandler(index)}
                                            placeholder={'0'}
                                        />
                                    </div>
                                </div>
                                <div className="col-6 col-lg-3">
                                    <div className="mb-lg-3">
                                        <label className="form-label d-lg-none">{t('form.label.token')}</label>
                                        <DropdownSelector
                                            currentItem={item.token}
                                            list={coinsList}
                                            setItem={(id) => {
                                                onChangeTokenHandler(index, id)
                                            }}
                                        />
                                    </div>
                                </div>
                                <div className="col-12 col-lg">
                                    {item.token === CUSTOM_DROPDOWN_ITEM.id ?
                                        <div className="mt-2 mt-lg-0 mb-lg-3">
                                            <InputElement
                                                errorText={item.customContractError}
                                                value={item.customContract}
                                                onChange={customContractHandler(index)}
                                                placeholder={t('form.label.tokenAddress')}
                                            />
                                        </div>
                                        :
                                        null
                                    }
                                </div>
                                <div className="col-auto col-lg-2">
                                    <div className="mb-2 mb-lg-3">
                                        <ButtonElement
                                            link
                                            additionalClass={'link-danger w-100 px-0'}
                                            onClick={() => {
                                                setPrices((prevState) => {
                                                    let newState = []
                                                    for (let i = 0; i < prevState.length; i++) {
                                                        if (i !== index) {
                                                            newState.push(prevState[i])
                                                        }
                                                    }
                                                    return newState
                                                })
                                            }}
                                        >{t('button.delete')}</ButtonElement>
                                    </div>
                                </div>
                            </div>
                        })}
                    </div>
                    <div className="py-2 pb-lg-0">
                        <ButtonElement
                            small
                            outline
                            onClick={() => {
                                setPrices((prevState) => {
                                    return [...prevState, getEmptyPrice()]
                                })
                            }}
                        >
                            <AddIcon/>
                            <span>{t('button.addMore')}</span>
                        </ButtonElement>
                    </div>
                </div>
                <div className="modal-footer justify-content-between">
                    <div className="mb-3 mb-lg-0 pe-4">
                        <div className="mb-1">
                            <span className="text-muted">{t('form.label.network')} </span>
                            <span>{CHAINS[currentNetwork].label}</span>
                        </div>
                        <div className="mb-1">
                            <span className="text-muted">{t('form.label.showcase')} </span>
                            <span>{displayName}</span>
                        </div>
                    </div>
                    <ButtonElement additionalClass={'flex-grow-1 flex-lg-grow-0'} onClick={addHandler}>
                        {t('button.addTicketsToShowcase')}
                    </ButtonElement>
                </div>
            </div>
        </div>
    </div>
}

export default ModalAddTicketsToShowcase
