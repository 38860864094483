import React, {useEffect} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {useTranslation} from 'react-i18next'
import {AppDispatch} from '../../store/store'
import {sendRequestWithAuth, setModalCreateTicketLevels, setModalEditString} from '../../store/appSlice'
import {getSelectedEventId, getSelectedEventName} from '../../store/eventsSlice'
import {
    getSelectedTicketLevelName,
    getTicketLevelId,
    getTicketLevels,
    requestTicketLevels,
    setTicketLevelId, putTicketLevelTitle, setTicketLevels
} from '../../store/ticketsSlice'
import {ButtonElement, DropdownPlaceholder, DropdownSelector, IDropdownItem} from '../elements'
import {EditIcon} from '../icons'
import {getJwt} from '../../store/authSlice'

interface propsType {
    error?: string
}

const SelectTicketLevel = (props: propsType) => {
    const {t} = useTranslation()
    const eventName = useSelector(getSelectedEventName)
    const jwt = useSelector(getJwt)
    const selectedEventId = useSelector(getSelectedEventId)
    const ticketLevelId = useSelector(getTicketLevelId)
    const ticketLevelName = useSelector(getSelectedTicketLevelName)
    const ticketLevels = useSelector(getTicketLevels)

    const dispatch = useDispatch<AppDispatch>()

    useEffect(() => {
        if (selectedEventId) {
            dispatch(sendRequestWithAuth(requestTicketLevels(selectedEventId)))
        }
        if (ticketLevels) {
            dispatch(setTicketLevels(null))
        }
    }, [selectedEventId, jwt])
    useEffect(() => {
        if (ticketLevels && ticketLevels.length > 0) {
            dispatch(setTicketLevelId(ticketLevels[0].id))
        }
    }, [ticketLevels])

    const createTicketLevelsHandler = () => {
        dispatch(setModalCreateTicketLevels(true))
    }
    const ticketLevelsList: IDropdownItem[] = ticketLevels?.map((item): IDropdownItem => {
        return {id: item.id, name: `${item.title} (${item.level})`}
    }) || []
    const editHandler = () => {
        dispatch(setModalEditString({
            title: t('modal.title.edit', {name: t('form.label.ticketLevel')}),
            label: t('form.label.ticketLevel'),
            text: ticketLevelName,
            handler: (text: string) => {
                dispatch(sendRequestWithAuth(putTicketLevelTitle(text)))
            },
        }))
    }

    return <>
        <div className="d-flex align-items-center justify-content-between">
            <label className="form-label">{t('form.label.ticketLevel')} <span className="text-danger">*</span></label>
            {ticketLevelName !== '' ?
                <ButtonElement
                    small
                    outline
                    type={'secondary'}
                    additionalClass={'border-0 p-1 mb-1 mt-n2'}
                    onClick={editHandler}
                >
                    <EditIcon/>
                </ButtonElement>
                :
                null
            }
        </div>
        {ticketLevels ?
            <DropdownSelector
                list={ticketLevelsList}
                setItem={(id) => {
                    dispatch(setTicketLevelId(id))
                }}
                currentItem={ticketLevelId}
                firstItem={{id: 'firstItem', name: t('button.addLevel')}}
                firstItemAction={createTicketLevelsHandler}
                errorText={props.error}
            />
            :
            <DropdownPlaceholder title={eventName === '' ? t('status.selectEvent') : `${t('status.loading')}...`}/>
        }
    </>
}

export default SelectTicketLevel
