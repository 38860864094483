import React, {useContext, useEffect, useRef, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {Link, useMatch} from 'react-router-dom'
import {useConnectWallet} from '@web3-onboard/react'
import {useTranslation} from 'react-i18next'
import {ButtonElement, CopyToClipboardBlock} from '../elements'
import {closeDropdown, compactString, openDropdown} from '../../utils/functions'
import {resetAuth} from '../../store/authSlice'
import {AppDispatch} from '../../store/store'
import {getName, requestAddress} from '../../store/ensSlice'
import {getCurrentNetwork, getWalletAddress} from '../../store/appSlice'
import {CHAINS} from '../../utils/constants'
import {ChevronDownIcon} from '../icons'
import {ThemeContext} from '../../context'
import {getCurrentEventId, getEvent} from '../../store/eventsSlice'
import {changeLang} from '../../i18n/config'

const UserMenu = () => {
    const {t, i18n} = useTranslation()
    const {dark} = useContext(ThemeContext)
    const [cursorOnElement, setCursorOnElement] = useState(false)
    const [elementOpened, setElementOpened] = useState(false)
    const currentNetwork = useSelector(getCurrentNetwork)
    const walletAddress = useSelector(getWalletAddress)
    const nameAddress = useSelector(getName(walletAddress || ''))
    const [{wallet}, connect, disconnect] = useConnectWallet()
    const userMenuBlockRef = useRef(null)
    const urlMatch = useMatch('/event/:organizerUrl/:eventUrl')
    const currentEventId = useSelector(getCurrentEventId)
    const event = useSelector(getEvent(currentEventId))

    const dispatch = useDispatch<AppDispatch>()

    useEffect(() => {
        if (walletAddress && nameAddress === undefined && currentNetwork && CHAINS[currentNetwork].tld !== '') {
            dispatch(requestAddress(walletAddress))
        }
    }, [currentNetwork, nameAddress, walletAddress])

    const closeUserMenu = (close?: boolean) => {
        closeDropdown({
            close,
            cursorOnDropdown: cursorOnElement,
            setClosed: () => setElementOpened(false),
            setCursorOnDropdown: () => setCursorOnElement(false),
        })
    }
    const disconnectWallet = async () => {
        if (wallet) {
            await disconnect(wallet)
            dispatch(resetAuth())
        }
    }
    const openUserMenu = () => {
        openDropdown({
            closeMenu: closeUserMenu,
            element: userMenuBlockRef,
            setOpened: () => setElementOpened(true)
        })
    }

    return <div
        className="s-user"
        ref={userMenuBlockRef}
        onClick={elementOpened ? () => closeUserMenu() : openUserMenu}
        onMouseEnter={openUserMenu}
        onMouseLeave={() => {
            closeUserMenu()
        }}
    >
        <div className="btn-group">
            <ButtonElement outline small additionalClass={'s-user__toggle'}>
                <span className="s-user__avatar"><img src={`/img/icons/user-${dark ? 'dark' : 'light'}-mode.svg`} alt=""/></span>
                <span className="me-2 d-none d-md-block">
                    {nameAddress !== undefined && nameAddress !== '' ? nameAddress : compactString(walletAddress || '')}
                </span>
                <ChevronDownIcon/>
            </ButtonElement>
            <CopyToClipboardBlock
                text={walletAddress || ''}
                small
                additionalClass={'btn-outline-primary px-2 d-none d-md-flex'}
            />
        </div>
        {elementOpened ?
            <div
                className="btn-dropdown s-user__menu"
                onMouseEnter={() => {
                    setCursorOnElement(true)
                    openUserMenu()
                }}
                onMouseLeave={() => {
                    closeUserMenu(true)
                }}
                onClick={() => {
                    closeUserMenu(true)
                }}
            >
                <ul className="inner">
                    <li className="d-md-none">
                        <button className="btn btn-link item address">
                            <span className="me-2">
                                {nameAddress !== undefined && nameAddress !== '' ? nameAddress : compactString(walletAddress || '')}
                            </span>
                            <ChevronDownIcon/>
                        </button>
                    </li>
                    <li>
                        <Link to="/tickets" className="item">{t('menu.myNfts')}</Link>
                    </li>
                    <li>
                        <Link to="/whitelists" className="item">{t('menu.whitelists')}</Link>
                    </li>
                    <li>
                        <Link to="/tickets/check" className="item">{t('menu.checkTickets')}</Link>
                    </li>
                    {urlMatch && (!event || event.organizer !== walletAddress) ?
                        <>
                            <li>
                                <Link to="/lazy/mint" className="item">{t('menu.tickets')}</Link>
                            </li>
                            <li>
                                <Link to="/lazy/showcases" className="item">{t('menu.showcase')}</Link>
                            </li>
                        </>
                        :
                        null
                    }
                    <li>
                        <Link to="/mintcollection" className="item">{t('menu.publicCollection')}</Link>
                    </li>
                    <li className="mt-md-2">
                        <a className="border-top item" href={'#'} onClick={changeLang}>
                            <span>{t('menu.language')}</span>
                            <span className="text-muted">{i18n.language === 'en' ? 'RU' : 'EN'}</span>
                        </a>
                    </li>
                    <li>
                        <button onClick={disconnectWallet} className="border-top item disconnect">
                            {t('menu.disconnect')}
                        </button>
                    </li>
                </ul>
            </div>
            :
            null
        }
    </div>
}

export default UserMenu
