import React, {useRef, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {useTranslation} from 'react-i18next'
import {getModalCreateEvent, sendRequestWithAuth, setModalCreateEvent} from '../../store/appSlice'
import {ButtonElement, DateElement, InputElement, TextareaElement} from '../elements'
import {AppDispatch} from '../../store/store'
import {CloseIcon} from '../icons'
import {postEvent} from '../../store/eventsSlice'

interface propsType {
    show: boolean
}

const ModalCreateEvent = (props: propsType) => {
    const {t} = useTranslation()
    const modalFade = useRef(null)
    const [eventTitle, setEventTitle] = useState('')
    const [titleError, setTitleError] = useState('')
    const [startTime, setStartTime] = useState<Date | null>(new Date(Date.now()))
    const [startError, setStartError] = useState('')
    const [endTime, setEndTime] = useState<Date | null>(new Date(Date.now() + 3600000))
    const [endError, setEndError] = useState('')
    const [eventDescription, setEventDescription] = useState('')
    const modal = useSelector(getModalCreateEvent)

    const dispatch = useDispatch<AppDispatch>()

    const closeModal = () => {
        dispatch(setModalCreateEvent(false))
    }
    const addEventHandler = () => {
        if (!modal) {
            return
        }

        let error = false
        if (!startTime) {
            error = true
            setStartError(t('error.wrong', {name: t('form.label.startDate')}))
        } else {
            setStartError('')
        }
        if (!endTime) {
            error = true
            setEndError(t('error.wrong', {name: t('form.label.endDate')}))
        } else if (endTime <= (startTime || 0)) {
            error = true
            setEndError(t('error.mustBeGreater', {name: t('form.label.endDate'), value: t('form.label.startDate')}))
        } else {
            setEndError('')
        }
        if (eventTitle === '') {
            error = true
            setTitleError(t('wrong', {name: t('form.label.title')}))
        } else {
            setTitleError('')
        }

        if (error || !startTime || !endTime) {
            return
        }
        dispatch(sendRequestWithAuth(postEvent({
            name: eventTitle,
            description: eventDescription,
            startTime,
            endTime,
        })))
    }

    if (!modal) {
        closeModal()
    }

    if (!props.show) {
        return null
    }

    return <div
        className={`modal modal-lg fade show`}
        ref={modalFade}
        tabIndex={-1}
        aria-labelledby="Create a event"
        aria-hidden="true"
        style={{display: 'block'}}
        onMouseDown={(e) => {
            if (e.target === modalFade.current) {
                closeModal()
            }
        }}
    >
        <div className="modal-dialog">
            <div className="modal-content">
                <div className="modal-header">
                    <h2 className="modal-title">{t('modal.title.createEvent')}</h2>
                    <button className="btn p-2 btn-with-opacity btn-modal-close" onClick={closeModal}>
                        <CloseIcon/>
                    </button>
                </div>
                <div className="modal-body pt-0">
                    <div className="row">
                        <div className="col-12">
                            <div className="mb-4">
                                <InputElement
                                    value={eventTitle}
                                    onChange={setEventTitle}
                                    errorText={titleError}
                                    label={t('form.label.title')}
                                />
                            </div>
                        </div>
                        <div className="col-12 col-sm-6">
                            <div className="mb-4">
                                <DateElement
                                    value={startTime}
                                    onChange={setStartTime}
                                    label={t('form.label.startDate')}
                                    errorText={startError}
                                />
                            </div>
                        </div>
                        <div className="col-12 col-sm-6">
                            <div className="mb-4">
                                <DateElement
                                    value={endTime}
                                    onChange={setEndTime}
                                    label={t('form.label.endDate')}
                                    errorText={endError}
                                />
                            </div>
                        </div>
                        <div className="col-12">
                            <div className="mb-4">
                                <TextareaElement
                                    value={eventDescription}
                                    onChange={setEventDescription}
                                    label={t('form.label.description')}
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="modal-footer justify-content-end">
                    <ButtonElement
                        additionalClass={'flex-grow-1 flex-lg-grow-0'}
                        onClick={addEventHandler}
                    >{t('button.save')}</ButtonElement>
                </div>
            </div>
        </div>
    </div>
}

export default ModalCreateEvent
