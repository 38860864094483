import React from 'react'
import {useTranslation} from 'react-i18next'
import {CrossIcon} from '../icons'
import {compactString} from '../../utils/functions'
import {InputElement} from '../elements'

interface PropsType {
    address: string
    amount: string
    delHandler: (index: number) => void
    editHandler: (index: number, amount: string) => void
    eipPrefix: string
    icon: string
    index: number
    label: string
}

const CollateralRow = (props: PropsType) => {
    const {t} = useTranslation()
    const {address, amount, delHandler, editHandler, eipPrefix, icon, index, label} = props

    return <div className="row-table__item mb-2">
        <div className="row align-items-center">
            <div className="mb-2 col-2 col-md-1 order-md-1">#{index + 1}</div>
            <div className="mb-2 col-5 col-md-3 col-lg-2 order-md-2">
                <img className="i-coin me-2" src={icon} alt={label}/>
                <span className="align-middle">{compactString(address)}</span>
            </div>
            <div className="mb-2 col-4 col-md-3 order-md-4"><span
                className="text-muted">{eipPrefix}-20</span></div>
            <div className="mb-2 mt-2 mt-md-0 col-10 offset-2 col-md offset-md-0 order-md-3">
                <div className="d-md-none text-muted mb-1">{t('word.amount')}:</div>
                <InputElement
                    small
                    onChange={(value: string) => {
                        editHandler(index, value)
                    }}
                    value={amount}
                />
            </div>
            <button className="btn p-2 btn-with-opacity btn-item-delete" onClick={() => delHandler(index)}>
                <CrossIcon/>
            </button>
        </div>
    </div>
}

export default CollateralRow
