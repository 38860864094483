import React from 'react'
import {useSelector} from 'react-redux'
import {getNames} from '../../store/ensSlice'
import {CrossIcon} from '../icons'

interface PropsType {
    address: string
    delHandler: (index: number) => void
    index: number
}

const RecipientRow = (props: PropsType) => {
    const {address, delHandler, index} = props
    const ENSNames = useSelector(getNames)

    return <div className="row-table__item mb-2">
        <div className="row align-items-center">
            <div className="mb-2 col-2 col-lg-1">#{index + 1}</div>
            <div className="mb-2 col">{address} {ENSNames[address] ? `(${ENSNames[address]})` : null}</div>
            <button className="btn p-2 btn-with-opacity btn-item-delete" onClick={() => {delHandler(index)}}>
                <CrossIcon/>
            </button>
        </div>
    </div>
}

export default RecipientRow
