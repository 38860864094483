import React, {useEffect, useRef, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {useTranslation} from 'react-i18next'
import {
    getCurrentNetwork,
    getModalAddShowcaseToEvent,
    getWalletAddress, sendRequestWithAuth,
    setModalAddShowcaseToEvent,
} from '../../store/appSlice'
import {ButtonElement, DropdownSelector, IDropdownItem} from '../elements'
import {AppDispatch} from '../../store/store'
import {CloseIcon} from '../icons'
import {addShowcaseToEvent, getCurrentEventId} from '../../store/eventsSlice'
import {getDisplays, requestDisplays} from '../../store/launchpadSlice'
import {getDisplays as getLazyDisplays, requestDisplays as requestLazyDisplays} from '../../store/lazyMintingSlice'
import {getTicketLevels, requestTicketLevels, setTicketLevels} from '../../store/ticketsSlice'
import {CHAINS} from '../../utils/constants'
import {getShowcases, requestShowcases} from '../../store/showcaseV2Slice'

interface propsType {
    show: boolean
}

const ModalAddShowcaseToEvent = (props: propsType) => {
    const {t} = useTranslation()
    const modalFade = useRef(null)
    const [showcase, setShowcase] = useState('')
    const [levelId, setLevelId] = useState(null)
    const currentNetwork = useSelector(getCurrentNetwork)
//    const displays = useSelector(getDisplays)
    const eventId = useSelector(getCurrentEventId)
//    const lazyDisplays = useSelector(getLazyDisplays)
    const modal = useSelector(getModalAddShowcaseToEvent)
    const showcases = useSelector(getShowcases)
    const ticketLevels = useSelector(getTicketLevels)
    const walletAddress = useSelector(getWalletAddress)
    let showcasesList: IDropdownItem[] = []
/*
    for (let item of displays || []) {
        if (currentNetwork) {
            showcasesList.push({
                id: `${CHAINS[currentNetwork].launchpadContract}-${item.hash}`,
                name: item.name,
            })
        }
    }
    for (let item of lazyDisplays || []) {
        if (currentNetwork) {
            showcasesList.push({
                id: `${CHAINS[currentNetwork].lazyMintingContract}-${item.hash}`,
                name: item.name,
            })
        }
    }
*/
    for (let item of showcases || []) {
        if (currentNetwork) {
            showcasesList.push({
                id: `${CHAINS[currentNetwork].showcaseV2Contract}-${item.hash}`,
                name: item.name,
            })
        }
    }
    let levelsList: IDropdownItem[] = []
    for (let item of ticketLevels || []) {
        levelsList.push({
            id: item.id,
            name: item.title,
        })
    }

    const dispatch = useDispatch<AppDispatch>()

    useEffect(() => {
        return () => {
            dispatch(setTicketLevels(null))
        }
    }, [])
    useEffect(() => {
        if (!currentNetwork) {
            return
        }

        if (walletAddress) {
/*
            dispatch(requestDisplays())
            dispatch(requestLazyDisplays())
*/
            dispatch(requestShowcases())
        }
    }, [walletAddress, currentNetwork])
    useEffect(() => {
        if (eventId !== null) {
            dispatch(sendRequestWithAuth(requestTicketLevels(eventId)))
            setLevelId(null)
        }
    }, [eventId])

    const closeModal = () => {
        dispatch(setModalAddShowcaseToEvent(false))
    }
    const addHandler = () => {
        const splitStr = showcase.split('-')
        let name = ''
        for (let item of showcasesList) {
            if (item.id === showcase) {
                name = item.name
                break
            }
        }
        let linkTitle = ''
        for (let item of ticketLevels || []) {
            if (item.id === levelId) {
                linkTitle = item.title
                break
            }
        }
        if (!modal || !currentNetwork || splitStr.length !== 2 || levelId === '' || name === '' || linkTitle === '') {
            return
        }

        dispatch(addShowcaseToEvent({
            contract: splitStr[0],
            name: name,
            network: currentNetwork,
            linkTitle,
        }))
    }

    if (!modal) {
        closeModal()
    }

    if (!props.show) {
        return null
    }

    return <div
        className={`modal modal-lg fade show`}
        ref={modalFade}
        tabIndex={-1}
        aria-labelledby="Edit a showcase"
        aria-hidden="true"
        style={{display: 'block'}}
        onMouseDown={(e) => {
            if (e.target === modalFade.current) {
                closeModal()
            }
        }}
    >
        <div className="modal-dialog">
            <div className="modal-content">
                <div className="modal-header">
                    <h2 className="modal-title">{t('modal.title.addShowcaseToEvent')}</h2>
                    <button className="btn p-2 btn-with-opacity btn-modal-close" onClick={closeModal}>
                        <CloseIcon/>
                    </button>
                </div>
                <div className="modal-body pt-0">
                    <div className="row">
                        <div className="col-6">
                            <div className="mb-4">
                                <DropdownSelector
                                    list={showcasesList}
                                    setItem={setShowcase}
                                    currentItem={showcase}
                                    label={`${t('form.label.showcase')} *`}
                                />
                            </div>
                        </div>
                        <div className="col-6">
                            <div className="mb-4">
                                <div className="mb-4">
                                    <DropdownSelector
                                        list={levelsList}
                                        setItem={setLevelId}
                                        currentItem={levelId}
                                        label={`${t('form.label.ticketLevel')} *`}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="modal-footer justify-content-end">
                    <ButtonElement
                        additionalClass={'flex-grow-1 flex-lg-grow-0'}
                        onClick={addHandler}
                        disabled={showcase === '' || levelId === ''}
                    >{t('button.add')}</ButtonElement>
                </div>
            </div>
        </div>
    </div>
}

export default ModalAddShowcaseToEvent
